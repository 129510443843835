//utils
import React, { useRef } from "react";
import { useInterObserver } from "../../assets/customHooks/useInterObserver";

// components
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Loading from "../uncommon/Loading";
import Section_title from "../common/Section_title";
import MarketImage from "../common/MarketImage";
import Zoom from "react-medium-image-zoom";
import { useScreenWidth } from "../../assets/customHooks/useScreenWidth";

// images
import { banner_zero, banner_hb, HbBannerText, ZeroBannerText } from "../../assets/images/images";
import { useBby } from "../../assets/customHooks/useBby";
import Bby from "../common/Bby";

// 画像クリックで拡大する際の大きさのマージン
const ZOOM_MARGIN_PC = 100;
const ZOOM_MARGIN_MOBILE = 10;

/**
 * 市場概要セクションを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.MarketOverViewTopZeroData - AUCTION ZEROの市場データ
 * @param {Object} props.MarketOverViewTopHbData - HB AUCTIONの市場データ
 * @returns {JSX.Element} 市場概要セクションのJSX要素
 */
export default function Market_sec({ MarketOverViewTopZeroData, MarketOverViewTopHbData }) {
    useBby();
    const { isPcView } = useScreenWidth();

    const innerRef = useRef(null);
    useInterObserver([innerRef], 0.2);

    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    useInterObserver([contentRef1, contentRef2], 0.1, 200);

    return (
        <section id="Market_sec">
            <div className="inner" ref={innerRef}>
                <Section_title eng="Market" jp="市場概要" colorClass="green" />
                <div className="market_details">
                    当社では<Bby>ZERO</Bby>と<Bby>HBA</Bby>の２つの市場を開催しております。
                    <br />
                    ご参加には会員登録をして頂きます。
                    <br />
                    また、どちらかに会員登録して頂ければ２つの市場をご利用いただけます。
                    <br />
                </div>
                <div className="flexContainer">
                    {/* AUCTION ZERO*/}
                    <div className="market_child" ref={contentRef1}>
                        <MarketImage bgImage={banner_zero} textImage={ZeroBannerText} />
                        <div className="">
                            <>
                                {MarketOverViewTopZeroData ? (
                                    <>
                                        <p className="subtitle mt-3">取扱商品</p>
                                        <div className="product_names">
                                            <span className="bby">ブランド品</span>
                                            <span className="bby">{MarketOverViewTopZeroData.productNames}</span>
                                        </div>
                                        <div className="swiperContainer">
                                            <Swiper
                                                slidesPerView={"auto"}
                                                centeredSlides={true}
                                                spaceBetween={10}
                                                loop={true}
                                                className={`cSwiper zero`}
                                            >
                                                {MarketOverViewTopZeroData.productImage.map((product, index) => {
                                                    return (
                                                        <SwiperSlide key={index}>
                                                            <Zoom
                                                                zoomMargin={
                                                                    isPcView ? ZOOM_MARGIN_PC : ZOOM_MARGIN_MOBILE
                                                                }
                                                                overlayBgColorStart="rgba(255, 255, 50, 0)"
                                                                overlayBgColorEnd="rgba(0, 0, 0, 0.89)"
                                                            >
                                                                <img src={`${product.url}?fit=crop&w=600&h=600`} />
                                                            </Zoom>
                                                        </SwiperSlide>
                                                    );
                                                })}
                                            </Swiper>
                                        </div>
                                    </>
                                ) : (
                                    <Loading height="400px" />
                                )}
                            </>
                            <Link className="link_btn green center my-4" to="/Market_overview?auctionName=zero">
                                詳細を見る
                            </Link>
                        </div>
                    </div>
                    {/* HB AUCTION */}
                    <div className="market_child" ref={contentRef2}>
                        <MarketImage bgImage={banner_hb} textImage={HbBannerText} isHb={true} />
                        <div className="">
                            <>
                                {MarketOverViewTopHbData ? (
                                    <>
                                        <p className="subtitle mt-3">取扱商品</p>
                                        <div className="product_names bby"> {MarketOverViewTopHbData.productNames}</div>
                                        <div className="swiperContainer">
                                            <Swiper
                                                slidesPerView={"auto"}
                                                centeredSlides={true}
                                                spaceBetween={10}
                                                loop={true}
                                                className={`cSwiper hb`}
                                            >
                                                {MarketOverViewTopHbData.productImage.map((product, index) => {
                                                    return (
                                                        <SwiperSlide key={index}>
                                                            <Zoom
                                                                zoomMargin={
                                                                    isPcView ? ZOOM_MARGIN_PC : ZOOM_MARGIN_MOBILE
                                                                }
                                                                overlayBgColorStart="rgba(255, 255, 50, 0)"
                                                                overlayBgColorEnd="rgba(0, 0, 0, 0.89)"
                                                            >
                                                                <img src={`${product.url}?fit=crop&w=600&h=600`} />
                                                            </Zoom>
                                                        </SwiperSlide>
                                                    );
                                                })}
                                            </Swiper>
                                        </div>
                                    </>
                                ) : (
                                    <Loading height="400px" />
                                )}
                            </>
                            <Link className="link_btn green center my-4" to="/Market_overview?auctionName=hb">
                                詳細を見る
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
