import { useState, useEffect } from "react";
import {  fetchURL } from "./fetchURL";

/**
 * カスタムフック: useFetchReservation
 * 
 * マーケットスケジュールデータを取得し、ブランドオークションとHBオークションに分類するためのフックです。
 * 
 * @returns {Object} - 全てのスケジュールデータを含むオブジェクト
 * @property {Array} allSchedules.zeroMarketSchedules - ブランドオークションのスケジュール
 * @property {Array} allSchedules.hbMarketSchedules - HBオークションのスケジュール
 */
const useFetchReservation = () => {
    const [zeroMarketSchedules, setZeroMarketSchedules] = useState([]);
    const [hbMarketSchedules, setHbMarketSchedules] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let response;
            response = await fetch(`${fetchURL}/fetchContent.php?endpoint=market_schedules`);
            const body = await response.json(); 

            let scheduleArray;
            scheduleArray = parseScheduleCSV(body.schedules);
            
            scheduleArray.sort((a, b) => new Date(a.date) - new Date(b.date));

            const zero = scheduleArray.filter((schedule) => {
                return (
                    schedule.auction_name[0] === "ブランドオークション" &&
                    new Date(schedule.date).getTime() > new Date().getTime()
                );
            });
            const hb = scheduleArray.filter((schedule) => {
                return (
                    schedule.auction_name[0] === "HBオークション" &&
                    new Date(schedule.date).getTime() > new Date().getTime()
                );
            });
            setZeroMarketSchedules(zero);
            setHbMarketSchedules(hb);
        };
        fetchData();
    }, []);

    return { allSchedules: { zeroMarketSchedules, hbMarketSchedules } };
};

export default useFetchReservation;

/**
 * スケジュールCSVを解析し、スケジュールオブジェクトの配列を返します。
 *
 * @param {string} csv - 解析するCSV文字列
 * @returns {Array} - スケジュールオブジェクトの配列
 */
function parseScheduleCSV(csv) {
    const records = csv.split("\n");
    const schedules = records.map((record) => {
        return {
            date: record.split(",")[0],
            auction_name: [record.split(",")[1]],
            products: record.split(",")[2],
        };
    });
    return schedules;
}