// utils
import React, { useRef } from "react";
import { useInterObserver } from "../../assets/customHooks/useInterObserver";

/**
 * セクションタイトルコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.eng - 英語のタイトル
 * @param {string} props.jp - 日本語のタイトル
 * @param {string} props.colorClass - 色を指定するためのクラス名
 * @returns {JSX.Element} セクションタイトルのJSX要素
 */
function Section_title({ eng, jp, colorClass }) {
    const targetRef = useRef(null);
    useInterObserver([targetRef], 0.9, 500);
    return (
        <h3 className="Section_title" ref={targetRef}>
            <p className={`eng ${colorClass}`}>{eng}</p>
            <p className={`jp`}>{jp}</p>
        </h3>
    );
}

export default Section_title;
