import { useEffect } from "react";

/**
 * カスタムフック `useInterObserver` は、指定された要素がビューポートに入ったときに
 * クラスを追加するためのインターセクションオブザーバーを設定します。
 *
 * @param {Array} targetRefs - 監視対象の要素の参照の配列。
 * @param {number} [threshold=0.3] - 要素がビューポートに入ったとみなすための閾値。
 * @param {number} [delay=300] - クラスを追加するまでの遅延時間（ミリ秒）。
 */
const useInterObserver = (targetRefs, threshold = 0.3, delay = 300) => {
    if (!Array.isArray(targetRefs)) {
        console.warn("useInterObserver : targetRefs is not array.");
        console.warn(targetRefs);
    }
    useEffect(() => {
        targetRefs.forEach(targetRef => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            entry.target.classList.add("interSect");
                        }, delay);
                    }
                },
                {
                    threshold: threshold,
                }
            );
    
            if (targetRef.current) {
                observer.observe(targetRef.current);
            }
        });

    }, [targetRefs]);
};
export { useInterObserver };
