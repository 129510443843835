// utils
import React from "react";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

// components
import Section_title from "../components/common/Section_title";
import { Access_1 } from "../assets/images/images";

export default function Access() {
    useScrollTop();
    return (
        <div className="page" id="Access">
            <main>
                <Section_title eng="Venue Guide" jp="会場案内" colorClass="green" />
                <div className="contents wrapper">
                    <div className="content">
                        <iframe
                            className="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3247.8765801447325!2d139.6111216873845!3d35.507329907510695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185ed24a40f62b%3A0xc91ca195d49f151d!2z44OW44Op44Oz44OJ44Kq44O844Kv44K344On44Oz77y677yl77yy77yv!5e0!3m2!1sja!2sjp!4v1728296651826!5m2!1sja!2sjp"
                            width="800"
                            height="600"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                    <div className="content">
                        <p className="subtitle">電車でのご利用の場合</p>
                        <div className="detail">
                            <img src={Access_1} alt="Access_1" />
                            各地からの所要時間： <br />
                            <br />
                            横浜から
                            <br />
                            　横浜市営地下鉄ブルーライン　約11分 <br />
                            名古屋から
                            <br />
                            　JR東海道新幹線　約1時間21分 <br />
                            新大阪から
                            <br />
                            　JR東海道新幹線　約2時間14分 <br />
                            品川から
                            <br />
                            　JR東海道新幹線　約11分 <br />
                            東京から
                            <br />
                            　JR東海道新幹線　約17分 <br />
                            渋谷から
                            <br />
                            　東急東横線～JR横浜線　約27分 <br />
                            羽田空港から
                            <br />
                            　京急空港線～ <br />
                            　　横浜市営地下鉄ブルーライン　約43分
                        </div>
                    </div>
                    <div className="content">
                        <p className="subtitle">車でのご利用の場合</p>
                        <div className="detail">
                            <iframe
                                className="map"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1623.9397148328003!2d139.6123255067222!3d35.50725945283657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185f4cd4bd4137%3A0x3095cb0a35c41154!2z5paw5qiq5rWc56u555Sf56ysMuODk-ODqw!5e0!3m2!1sja!2sjp!4v1736558199562!5m2!1sja!2sjp"
                                width="800"
                                height="600"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                            首都高から
                            <br />
                            　首都高速横羽線「三ッ沢」出口から15分 <br />
                            <br />
                            第三京浜から
                            <br />
                            　港北インター出口から約10分 <br />
                            <br />
                            一般道
                            <br />
                            国道13号線新横浜駅前から1分
                            <br />
                            <br />
                            駐車場のご案内
                            <br />
                            <br />
                            会場付近には多数のコインパーキングがございます。ただし、各駐車場の料金体系にはくれぐれもご注意ください。
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
