// utils
import React from "react";
import { EffectFade, Autoplay } from "swiper/modules";

// components
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

/**
 * Hero_sec コンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array<Object>} props.topImages - 表示するトップ画像の配列
 * 
 * @returns {JSX.Element} ヒーローセクションを表示する JSX 要素
 */
function Hero_sec({ topImages }) {
    const chunkedImageArray = [];
    topImages.forEach((img, index) => {
        if (index % 2 === 0) {
            chunkedImageArray.push([img, topImages[index + 1] || topImages[0]]);
        }
    });

    return (
        <section id="Hero_sec">
            <div className="inner">
                <div className="upper">
                    <Swiper
                        effect={"fade"}
                        modules={[EffectFade, Autoplay]}
                        allowTouchMove={false}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: true,
                        }}
                        speed={1200}
                        className="mySwiper"
                    >
                        {chunkedImageArray.map((chunk, chunkIndex) => {
                            return (
                                <SwiperSlide key={chunkIndex}>
                                    {chunk.map((image, imageIndex) => {
                                        return (
                                            <React.Fragment key={imageIndex}>
                                                <img src={`${image.url}?fit=crop&w=800&h=800`} alt="hero" />
                                            </React.Fragment>
                                        );
                                    })}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className="vertical_title">笑いあふれる楽しい市場「人が集まる」「物が集まる」</div>
                </div>
                <div className="link_btns">
                    <Link className="link_btn orange" to="/Market_overview">
                        初めての方はこちら
                    </Link>
                    <Link className="link_btn green" to="/Reservation">
                        参加予約
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Hero_sec;
