import { useEffect } from "react";

/**
 * カスタムフック: useScrollTop
 * 
 * 指定された遅延時間後にページをトップにスクロールするためのフックです。
 *
 * @param {number} [delay=0] - ページをトップにスクロールするまでの遅延時間（ミリ秒）。
 */
const useScrollTop = (delay = 0) => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "instant" });
        }, delay);
    }, []);
};

export { useScrollTop };
