import { useState, useEffect } from "react";
import {  fetchURL } from "./fetchURL";

/**
 * カスタムフック: useFetchMarketOverViewHb
 * 
 * マーケット概要のHBデータを取得するためのフックです。
 * 
 * @returns {Object} - マーケット概要のHBデータを含むオブジェクト
 */
const useFetchMarketOverViewHb = () => {
    const [MarketOverViewHbData, setMarketOverViewHbData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let response;
            response = await fetch(`${fetchURL}/fetchContent.php?endpoint=market_overview_hb`);

            const body = await response.json();
            setMarketOverViewHbData(body.contents);
        };
        fetchData();
    }, []);

    return { MarketOverViewHbData };
};

export default useFetchMarketOverViewHb;
