import { fetchURL } from "../customHooks/fetchURL";

function highlightColoredText(input) {
    return input.replace(/\$(B\.)?([#\w]+)\/(.*?)\$/g, (match, bold, color, text) => {
        const fontWeight = bold ? "font-weight:bold;" : "";
        const formated = `<span style="${fontWeight}color:${color}">${text}</span>`;
        return formated;
    });
}

/**
 * ページ全体のスクロールを有効または無効にします。
 *
 * @param {boolean} bool - スクロールを無効にする場合はtrue、有効にする場合はfalse。
 */
function disableBodyScroll(bool) {
    document.documentElement.style.overflow = bool ? "hidden" : "";
}

/**
 * 日付文字列を指定された形式でフォーマットします。
 *
 * @param {string} dateStr - フォーマットしたい日付の文字列。
 * @param {string} type - フォーマットの種類。"mmdd"の場合は月日と曜日、それ以外は年/月/日形式。
 * @returns {string} フォーマットされた日付文字列。
 */
function dateFormater(dateStr, type) {
    const date = new Date(dateStr);
    let formated;
    if (type === "mmdd") {
        const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
        const dayOfWeek = weekdays[date.getDay()];
        formated = `${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)} (${dayOfWeek})`;
    } else {
        formated = `${date.getFullYear()}/${zeroPad(date.getMonth() + 1, 2)}/${zeroPad(date.getDate(), 2)}`;
    }
    return formated;

    function zeroPad(number, length) {
        return String(number).padStart(length, "0");
    }
}

/**
 * 指定されたファイルIDに基づいてファイルをダウンロードします。
 *
 * @param {string} fileID - ダウンロードしたいファイルのID。
 * @returns {Promise<void>} ファイルのダウンロードを開始します。
 */
async function dlHandler(fileID) {
    let response;
    response = await fetch(`${fetchURL}/fetchContent.php?endpoint=file_download`);

    const body = await response.json();
    const url = body[fileID].url;
    window.open(url, "_blank");
}

/**
 * URLのクエリパラメータから指定されたパラメータ名の値を取得します。
 *
 * @param {string} paramName - 取得したいクエリパラメータの名前。
 * @returns {string|null} 指定されたパラメータ名に対応する値。存在しない場合はnullを返します。
 */
function getParam(paramName) {
    const params = new URLSearchParams(window.location.search);
    const result = params.get(paramName);
    return result;
}

/**
 * 指定されたオークション名に基づいて、エントリーデートリストを取得します。
 *
 * @param {string} auctionName - 取得したいオークションの名前。'zero' または 'hb' のいずれか。
 * @returns {Promise<Array>} 指定されたオークション名に対応するスケジュールの配列を返します。
 */
async function getEntryDateList(auctionName) {
    let zeroMarketSchedules = null;
    let hbMarketSchedules = null;

    const fetchData = async () => {
        let response;
        response = await fetch(`${fetchURL}/fetchContent.php?endpoint=market_schedule2`);

        const body = await response.json();
        let scheduleArray;
        scheduleArray = body.contents;

        scheduleArray.sort((a, b) => new Date(b.date) - new Date(a.date));

        const zero = scheduleArray.filter((schedule) => schedule.auction_name[0] === "ブランドオークション");
        const hb = scheduleArray.filter((schedule) => schedule.auction_name[0] === "HBオークション");
        zeroMarketSchedules = zero;
        hbMarketSchedules = hb;
    };

    await fetchData();

    if (auctionName === "zero") {
        return zeroMarketSchedules;
    } else if (auctionName === "hb") {
        return hbMarketSchedules;
    }
}

/**
 * フォームの値を埋め込んだHTMLラベルとパラグラフを生成します。
 *
 * @param {string} formValue - フォームの入力値。
 * @param {string} title - ラベルとして表示するタイトル。
 * @returns {string} フォームの値が存在する場合は、ラベルとパラグラフを含むHTML文字列。存在しない場合は空文字列。
 */
function embedFormValue(formValue, title) {
    let result = formValue
        ? `<label style="padding: 0; font-weight: bold;">${title}</label>
    <p style="margin:0;margin-bottom:10px;">${formValue}</p>`
        : "";
    return result;
}

/**
 * フォームの入力値をローカルストレージから読み込む
 * @param {string} LOCAL_STORAGE_KEY - ローカルストレージのキー
 * @returns {Object} 保存された入力値のオブジェクト
 */
function loadInputValues(LOCAL_STORAGE_KEY) {
    try {
        const savedData = localStorage.getItem(LOCAL_STORAGE_KEY);
        return savedData ? JSON.parse(savedData) : {};
    } catch (error) {
        console.error("Failed to load input values:", error);
        return {};
    }
}

/**
 * フォームの入力値をローカルストレージに保存します
 * @param {string} LOCAL_STORAGE_KEY - ローカルストレージのキー
 * @param {string} formData - react final form で使用しているformData
 * @param {Array} unsaveValueNames - 保存しないフィールド名の配列
 * @returns {void}
 */
function saveInputValues(LOCAL_STORAGE_KEY, formData, unsaveValueNames = []) {
    const saveFormData = { ...formData }; // formDataのコピーを作成
    unsaveValueNames.forEach((name) => {
        delete saveFormData[name];
    });
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(saveFormData));
}

export {
    loadInputValues,
    saveInputValues,
    embedFormValue,
    getParam,
    highlightColoredText,
    dateFormater,
    dlHandler,
    getEntryDateList,
    disableBodyScroll,
};
