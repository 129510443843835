// utils
import React, { useRef } from "react";
import { dateFormater } from "../../assets/funcs/commonFuncs";
import { useInterObserver } from "../../assets/customHooks/useInterObserver";

// components
import { Link } from "react-router-dom";
import Section_title from "../common/Section_title";
import Loading from "../uncommon/Loading";
import { ChevronRight } from "akar-icons";

// 表示する直近のニュースの最大数
const VIEW_NEWS_LENGTH = 3;

/**
 * News_sec コンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array<Object>} props.allNews - 全てのニュース情報を含む配列
 * 
 * @returns {JSX.Element} ニュースセクションを表示する JSX 要素
 */
export default function News_sec({ allNews }) {
    const innerRef = useRef(null);
    useInterObserver([innerRef], 0.2);

    const contentRef = useRef(null);
    useInterObserver([contentRef], 0.2, 200);

    return (
        <section id="News_sec">
            <div className="inner interSectContainer" ref={innerRef}>
                <Section_title eng="News" jp="お知らせ" colorClass="green" />
                <div className="pt-3 interSectContainer" ref={contentRef}>
                    {allNews ? (
                        <div className="flexContainer">
                            <div className="newsContainer">
                                {allNews.map((news_child, index) => {
                                    if (index + 1 > VIEW_NEWS_LENGTH) return;
                                    return (
                                        <Link to={`/News?id=${news_child.id}`} key={index}>
                                            <div className="news_child">
                                                <div className="titles">
                                                    <div className="upper">
                                                        <span className="date">
                                                            {dateFormater(news_child.publishedAt)}
                                                        </span>
                                                        <span className="category">{news_child.category}</span>
                                                    </div>
                                                    <p className="title">{news_child.title}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                            <Link className="toNewsList mt-4 link_text" to="/News_list">
                                お知らせ一覧
                                <ChevronRight strokeWidth={2} size={15} />
                            </Link>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
        </section>
    );
}
