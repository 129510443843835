// utils
import React, { useEffect, useRef, useState } from "react";
import { dateFormater } from "../../assets/funcs/commonFuncs.jsx";
import { useInterObserver } from "../../assets/customHooks/useInterObserver";

// components
import { Link } from "react-router-dom";
import Section_title from "../common/Section_title";
import { ChevronRight } from "akar-icons";
import Loading from "../uncommon/Loading";
import { useScreenWidth } from "../../assets/customHooks/useScreenWidth";

// 表示する直近のスケージュールの最大数
const VIEW_SCHEDULE_LENGTH = 6;

/**
 * Market_schedule_sec コンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.allSchedules - 全てのスケジュール情報を含むオブジェクト
 * 
 * @returns {JSX.Element} 市場スケジュールセクションを表示する JSX 要素
 */
export default function Market_schedule_sec({ allSchedules }) {
    const [marketSchedules, setMarketSchedules] = useState([]);

    const innerRef = useRef(null);
    useInterObserver([innerRef], 0.1);

    const contentRef = useRef(null);
    useInterObserver([contentRef], 0.1, 200);

    const { screenWidth } = useScreenWidth();

    const Conv = {
        ブランドオークション: "zero",
        HBオークション: "hb",
    };

    useEffect(() => {
        const scheduleArray = [...allSchedules.hbMarketSchedules, ...allSchedules.zeroMarketSchedules];
        // dateプロパティでソート
        scheduleArray.sort((a, b) => new Date(a.date) - new Date(b.date));
        setMarketSchedules(scheduleArray);
    }, [allSchedules]);

    return (
        <section id="Market_schedule_sec">
            <div className="inner" ref={innerRef}>
                <Section_title eng="Schedule" jp="開催日程" colorClass="green" />
                <div className="interSectContainer" ref={contentRef}>
                    {marketSchedules.length !== 0 ? (
                        <>
                            <ul className="schedules">
                                {marketSchedules.map((schedule, index) => {
                                    if (index >= VIEW_SCHEDULE_LENGTH) return;
                                    const { date, auction_name, products } = schedule;
                                    const splitedDate = splitDate(date);
                                    return (
                                        <li className="schedule_child" key={index}>
                                            <Link
                                                to={`/Form_entry?date=${dateFormater(date)}&auctionName=${
                                                    Conv[auction_name[0]]
                                                }`}
                                            >
                                                <div className="splitedDate">
                                                    <span className="month">{splitedDate.thisMonth}</span>
                                                    <span className="slash"></span>
                                                    <span className="date">{splitedDate.thisDate}</span>
                                                    <span className="youbi">{splitedDate.thisYoubi}</span>
                                                </div>
                                                {screenWidth >= 500 ? (
                                                    <span className="auction_name">
                                                        {auction_name}　{products}
                                                    </span>
                                                ) : (
                                                    <span className="auction_name">
                                                        <p>{auction_name}</p>
                                                        <p>{products}</p>
                                                    </span>
                                                )}
                                                <div className="arrow">
                                                    <ChevronRight strokeWidth={3} size={14} />
                                                </div>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                            <Link className="toMore link_text" to="/Reservation">
                                開催日程一覧
                                <ChevronRight strokeWidth={2} size={15} />
                            </Link>
                        </>
                    ) : (
                        <Loading height="293px" />
                    )}
                </div>
            </div>
        </section>
    );
}

/**
 * 日付を分割して返す関数
 * 
 * @param {string} date - 日付文字列
 * @returns {Object} 分割された日付情報を含むオブジェクト
 */
function splitDate(date) {
    const d = new Date(date);
    const thisMonth = String(d.getMonth() + 1).padStart(2, "0");
    const thisDate = String(d.getDate()).padStart(2, "0");
    const youbi = d.getDay();

    const youbiArray = ["日", "月", "火", "水", "木", "金", "土"];
    const thisYoubi = youbiArray[youbi];
    return { thisMonth, thisDate, thisYoubi };
}
