import React, { createContext, useState } from "react";

export const ShuppinUploaderContext = createContext();

/**
 * ShuppinUploaderProvider コンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {React.ReactNode} props.children - コンテキストプロバイダー内にレンダリングされる子要素
 * 
 * @returns {JSX.Element} ShuppinUploaderContext プロバイダーを返します。
 */
export function ShuppinUploaderProvider({ children }) {
    const [shuppinUploaderVisible, setShuppinUploaderVisible] = useState(false);

    return (
        <ShuppinUploaderContext.Provider value={{ shuppinUploaderVisible, setShuppinUploaderVisible }}>
            {children}
        </ShuppinUploaderContext.Provider>
    );
}