import { useState, useEffect } from "react";
import { fetchURL } from "./fetchURL";

/**
 * カスタムフック `useFetchTopImages` は、トップ画像を取得し、状態として返します。
 *
 * @returns {Object} - `topImages` プロパティを持つオブジェクト。これは取得したトップ画像の配列です。
 */
const useFetchTopImages = () => {
    const [topImages, setTopImages] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let response;
            response = await fetch(`${fetchURL}/fetchContent.php?endpoint=top_images`);

            const body = await response.json();
            setTopImages(body.top_images);
        };
        fetchData();
    }, []);

    return { topImages };
};
export default useFetchTopImages;
