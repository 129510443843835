// utils
import { useInterObserver } from "../../assets/customHooks/useInterObserver";
import React, { useEffect, useRef, useState } from "react";

// components
import { Link } from "react-router-dom";
import { useScreenWidth } from "../../assets/customHooks/useScreenWidth";

/**
 * News_banner_sec コンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array<Object>} props.allNews - 全てのニュース情報を含む配列
 * 
 * @returns {JSX.Element} ニュースバナーセクションを表示する JSX 要素
 */
export default function News_banner_sec({ allNews }) {
    const { isPcView } = useScreenWidth();
    const [hasBannerNews, setHasBannerNews] = useState([]);

    const targetRef = useRef(null);
    useInterObserver([targetRef], 0.4);

    useEffect(() => {
        if (allNews) {
            const hasBannerNews = allNews.filter((news) => {
                return news.enable_banner && news.banner_mobile && news.banner_pc;
            });
            setHasBannerNews(hasBannerNews);
        }
    }, [allNews]);

    return (
        <section className="News_banner_sec">
            <div className="inner">
                <div className="interSectContainer pt-3" ref={targetRef}>
                    <div className="banners">
                        {hasBannerNews.map((news, index) => {
                            return (
                                <Link className="banner" to={`/News?id=${news.id}`} key={index}>
                                    <img src={isPcView ? news.banner_pc.url : news.banner_mobile.url} />
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}
