// utils
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { getParam } from "../assets/funcs/commonFuncs";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

// components
import Section_title from "../components/common/Section_title";
import { useLocation, useNavigate } from "react-router-dom";
import Bby from "../components/common/Bby";
import { useBby } from "../assets/customHooks/useBby";

export default function Membership_terms({ allTerms }) {
    useScrollTop();
    useBby();
    const navigate = useNavigate();
    const location = useLocation();

    const [auctionName, setAuctionName] = useState("");
    const [readed, setReaded] = useState(false); // スクロールが完了したか
    const [consent, setConsent] = useState(false); // 会員規約を確認したか

    const handleConsent = () => {
        // 同意した場合フォームへ進む
        if (consent) navigate(`/Form_join?auctionName=${auctionName}`)
    };

    const handleUnConsent = () => {
        // 同意しない場合トップに戻る
        navigate("/");
    };

    const handleReaded = (e) => {
        setConsent(e.target.checked);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
        const auctionName = getParam("auctionName");
        setAuctionName(auctionName);
        setReaded(false);
        setConsent(false);
        const termsElement = document.querySelector(".terms.wrapper");
        const handleScroll = () => {
            const isScrolledToBottom =
                termsElement.scrollHeight - termsElement.scrollTop <= termsElement.clientHeight + 1;
            if (isScrolledToBottom) {
                setReaded(true);
            }
        };

        termsElement.addEventListener("scroll", handleScroll);

        return () => {
            termsElement.removeEventListener("scroll", handleScroll);
        };
    }, [location.search]);
    return (
        <div className="page" id="Membership_terms">
            <main>
                <Section_title
                    eng={`${auctionName.toUpperCase()} Membership Form`}
                    jp={`${auctionName.toUpperCase()}入会フォーム`}
                    colorClass="green"
                />
                <div className="description wrapper">
                    {getDescription(auctionName)}
                    <br />
                    <br />
                    当オークションをご利用いただくには、以下の入会規約（以下、「本規約」といいます）をよくお読みいただき、内容にご同意いただく必要がございます。
                </div>
                <div className="terms wrapper">{auctionName === "zero" ? parse(allTerms.zeroTerms) : parse(allTerms.hbTerms)}</div>
                <div className="inputs">
                    <label htmlFor="consent">
                        <input
                            type="checkbox"
                            name="consent"
                            id="consent"
                            disabled={!readed ? true : false}
                            value={consent}
                            onChange={handleReaded}
                        />
                        <span className={!readed ? "noRead" : ""}>会員規約を確認しました</span>
                    </label>
                    <p className={`link_btn green mt-2 ${!consent ? "disable" : ""}`} onClick={handleConsent}>
                        同意する
                    </p>
                    <p className="link_btn orange mt-2 " onClick={handleUnConsent}>
                        同意しない
                    </p>
                </div>
            </main>
        </div>
    );
}

function getDescription(auctionName) {
    if (auctionName === "zero") {
        return (
            <>
                こちらは<Bby>オークションZERO入会フォーム</Bby>です。
                <br />
                HBオークションとは会員規約及び応募フォームが異なりますのでご注意ください。
            </>
        );
    } else if (auctionName === "hb") {
        return (
            <>
                こちらは<Bby>HBオークション入会フォーム</Bby>HBオークション入会フォームです。
                <br />
                オークションZEROとは会員規約及び応募フォームが異なりますのでご注意ください。
            </>
        );
    } else {
        <div className="description">オークション名が指定されていません</div>;
    }
}
