import { useEffect, useState } from "react";

/**
 * カスタムフック: useBby ※Border-bottom-yellow、画面内に入ったら黄色の下線をアニメーションします
 *
 * @param {Array} dependency - フックの依存配列
 * @param {string} [rootMargin="0px 0px -100px 0px"] - IntersectionObserverのrootMargin
 * @param {number} [delay=200] - 遅延時間（ミリ秒）
 */
const useBby = (dependency, rootMargin = "0px 0px -100px 0px", delay = 200) => {
    const [nodependency, setNodependency] = useState("inactive");

    useEffect(
        () => {
            // delay時間後にnodependencyを"active"に設定
            setTimeout(() => {
                setNodependency("active");
            }, delay);

            // IntersectionObserverのコールバック関数
            const observerCallback = (entries) => {
                entries.forEach((entry) => {
                    // 要素が画面内に入った場合の処理
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            // 要素に"interSect"クラスを追加
                            entry.target.classList.add("interSect");
                        }, delay);
                        // 観察を停止
                        observer.unobserve(entry.target);
                    }
                });
            };

            // IntersectionObserverのオプション設定
            const observerOptions = {
                root: null,
                rootMargin: rootMargin,
                threshold: 1,
            };

            // IntersectionObserverのインスタンスを作成
            const observer = new IntersectionObserver(observerCallback, observerOptions);

            // ".bby"クラスを持つ要素をすべて取得し、観察を開始 非同期対策で300msのディレイ
            setTimeout(() => {
                document.querySelectorAll(".bby").forEach((el) => observer.observe(el));
            }, 300);

            // クリーンアップ関数で観察を停止
            return () => {
                document.querySelectorAll(".bby").forEach((el) => observer.unobserve(el));
            };
        },
        dependency ? [...dependency] : [nodependency]
    );
};
export { useBby };
