// utils
import React, { useEffect, useContext, useState } from "react";
import { disableBodyScroll, dlHandler } from "../../assets/funcs/commonFuncs";
import { ShuppinUploaderContext } from "../contexts/ShuppinUploaderContext";

// components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";

import { CloudUpload, Cross, Download, LinkOut } from "akar-icons";
// images
import { line_icon2, twitter_icon } from "../../assets/images/images";
import { useScreenWidth } from "../../assets/customHooks/useScreenWidth";
import ShuppinhyouUploader from "./ShuppinhyouUploader";

/**
 * ハンバーガーメニューコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.isOpen - メニューが開いているかどうか
 * @param {function} props.toggleMenu - メニューを開閉するための関数
 * @param {number} props.HAMBURGER_BTN_SIZE - ハンバーガーボタンのサイズ
 * @returns {JSX.Element} ハンバーガーメニュー要素
 */
export default function HamburgerMenu(props) {
    const { isOpen, toggleMenu, HAMBURGER_BTN_SIZE } = props;

    const { screenWidth, isPcView } = useScreenWidth();
    const [expanded, setExpanded] = useState({ 1: false, 2: false, 3: false });

    const { shuppinUploaderVisible, setShuppinUploaderVisible } = useContext(ShuppinUploaderContext);

    const toggleShuppinUploaderVisible = (visible) => {
        setShuppinUploaderVisible(visible);
    };

    const handleExpanded = (num) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [num]: !prevExpanded[num],
        }));
    };

    const closeHandler = () => {
        if (!isPcView) {
            toggleMenu();
            setExpanded({ 1: false, 2: false, 3: false });
        }
    };

    useEffect(() => {
        if (isPcView) {
            disableBodyScroll(false);
        }
    }, [screenWidth]);

    return (
        <div id="HamburgerMenu" className={isOpen ? "isOpen" : ""}>
            <div className="inner">
                <div className="closeHamburger" onClick={closeHandler}>
                    <Cross strokeWidth={2} size={HAMBURGER_BTN_SIZE} />
                    <p>閉じる</p>
                </div>
                <Link className="HamburgerMenu_Link" to="/Market_overview" onClick={closeHandler}>
                    市場概要
                    <ChevronRightIcon />
                </Link>
                {/* 入会申込 */}
                <Accordion
                    expanded={expanded["2"]}
                    onChange={() => {
                        handleExpanded(2);
                    }}
                >
                    <AccordionSummary expandIcon={expanded["2"] ? <RemoveIcon /> : <AddIcon />}>
                        <Typography>入会申込</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Link
                            className="HamburgerMenu_Link"
                            to="/Membership_terms?auctionName=zero"
                            onClick={closeHandler}
                        >
                            オークションZERO
                            <ChevronRightIcon />
                        </Link>
                        <Link
                            className="HamburgerMenu_Link"
                            to="/Membership_terms?auctionName=hb"
                            onClick={closeHandler}
                        >
                            HBオークション
                            <ChevronRightIcon />
                        </Link>
                    </AccordionDetails>
                </Accordion>
                {/* 参加申込 */}
                <Accordion
                    expanded={expanded["3"]}
                    onChange={() => {
                        handleExpanded(3);
                    }}
                >
                    <AccordionSummary expandIcon={expanded["3"] ? <RemoveIcon /> : <AddIcon />}>
                        <Typography>参加申込</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Link className="HamburgerMenu_Link" to="/Reservation?auctionName=zero" onClick={closeHandler}>
                            オークションZERO
                            <ChevronRightIcon />
                        </Link>
                        <Link className="HamburgerMenu_Link" to="/Reservation?auctionName=hb" onClick={closeHandler}>
                            HBオークション
                            <ChevronRightIcon />
                        </Link>
                    </AccordionDetails>
                </Accordion>
                {/* 売りたい方 */}
                <Accordion
                    expanded={expanded["1"]}
                    onChange={() => {
                        handleExpanded(1);
                    }}
                >
                    <AccordionSummary expandIcon={expanded["1"] ? <RemoveIcon /> : <AddIcon />}>
                        <Typography>売りたい方</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Link className="HamburgerMenu_Link" to="/Sell" onClick={closeHandler}>
                            出品方法
                            <ChevronRightIcon />
                        </Link>
                        <Link className="HamburgerMenu_Link" to="/Consignment_Sales" onClick={closeHandler}>
                            委託販売
                            <ChevronRightIcon />
                        </Link>
                    </AccordionDetails>
                </Accordion>
                <Link className="HamburgerMenu_Link" to="/QnA" onClick={closeHandler}>
                    よくある質問
                    <ChevronRightIcon />
                </Link>
                <Link className="HamburgerMenu_Link" to="/Access" onClick={closeHandler}>
                    会場案内
                    <ChevronRightIcon />
                </Link>
                <Link className="HamburgerMenu_Link" to="/Company_Profile" onClick={closeHandler}>
                    会社概要
                    <ChevronRightIcon />
                </Link>

                {/* 出品リスト */}
                <div className="SellList">
                    <p className="HamburgerMenu_Link">出品リスト</p>
                    <div className="btns">
                        <p
                            className="link_btn green center"
                            to="/"
                            onClick={() => {
                                dlHandler("shuppinhyou");
                            }}
                        >
                            ダウンロード
                            <Download strokeWidth={2} size={isPcView ? 20 : 36} />
                        </p>
                        <p className="link_btn orange center" onClick={toggleShuppinUploaderVisible}>
                            アップロード
                            <CloudUpload strokeWidth={2} size={isPcView ? 20 : 36} />
                        </p>
                    </div>
                </div>
                <div className="bottom">
                    <Link className="link_btn green center mt-3" to="/Form_contact" onClick={closeHandler}>
                        お問い合わせ
                        <LinkOut strokeWidth={2} size={isPcView ? 20 : 36} />
                    </Link>
                    <div className="icons">
                        <a
                            href="https://s.lmes.jp/landing-qr/2005799632-Rkmb4nem?uLand=ZlpeeT"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="line_icon"
                        >
                            <img src={line_icon2} alt="line_icon" />
                        </a>
                        <a
                            href="https://x.com/auction_zero"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="line_icon"
                        >
                            <img src={twitter_icon} alt="line_icon" />
                        </a>
                    </div>
                </div>
            </div>
            <ShuppinhyouUploader
                visible={shuppinUploaderVisible}
                toggleShuppinUploaderVisible={toggleShuppinUploaderVisible}
            />
        </div>
    );
}
