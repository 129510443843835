// utils
import React from "react";
import { dlHandler } from "../assets/funcs/commonFuncs";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

// components
import Section_title from "../components/common/Section_title";
import { Download } from "akar-icons";

export default function Consignment_Sales() {
    useScrollTop();
    return (
        <div className="page" id="Consignment_Sales">
            <main>
                <Section_title eng="Consignment Sale" jp="委託販売" colorClass="green" />
                <section className="wrapper">
                    <div className="texts">
                        <p className="subtitle">手数料</p>
                        委託販売には以下の手数料が発生いたします <br />
                        <br />
                        入会金：10,000円＋税　初回登録時のみ
                        <br />
                        参加費：3,000円＋税
                        <br />
                        売り手数料
                        <br />
                        振込手数料
                        <br />
                        返送送料（必要に応じて）
                        <p className="subtitle">委託出品依頼書 (初回のみ)</p>
                        必要事項を記入し商品と同送ください
                        <p
                            className="link_btn center mt-3"
                            onClick={() => {
                                dlHandler("itakuShuppinIraisho");
                            }}
                        >
                            ダウンロード
                            <Download strokeWidth={2} size={20} />
                        </p>
                        <p className="subtitle">出品表</p>
                        商品に番号付きの荷札等を取り付けてください
                        <br />
                        <br />
                        「出品表」をダウンロードし、必要事項を記入してください
                        <br />
                        <br />
                        指値はお客様が希望する最低販売価格となります
                        <p
                            className="link_btn center mt-3"
                            onClick={() => {
                                dlHandler("shuppinhyou");
                            }}
                        >
                            ダウンロード
                            <Download strokeWidth={2} size={20} />
                        </p>
                        <p className="subtitle">商品の発送について</p>
                        上記「取引明細書」を同封の上、ご送付ください
                        <br />
                        <br />
                        可能であれば、「取引明細書」を入れる箱に「リスト在中」などと記載をお願いいたします
                        <br />
                        <br />
                        常設会場となっておりますので、ご準備が整い次第、随時お送りください
                        <br />
                        <br /> 送付先住所
                        <br /> 〒222-0033
                        <br />
                        横浜市港北区新横浜2－7－19　竹生ビル3F <br />
                        電話番号：045-620-5513
                        <p className="subtitle">当日の出品について</p>
                        最低販売価格に満たない商品は返送いたします
                        <br />
                        <br />
                        交渉も賜りますが、1万円以下の商品は原則として取り扱いません
                        <br />
                        <br />
                        当日、お客様から値引き交渉があった場合はご連絡する可能性があります
                        <p className="subtitle">精算方法について</p>
                        誠に恐れ入りますが、当日のご連絡は致しかねます。
                        <br />
                        <br />
                        翌日15時までに「精算書」をメールにてお送りいたしますので、ご確認をお願い申し上げます
                        <p className="subtitle">当日の出品について</p>
                        原則として、翌銀行営業日に貴社指定の口座へお振り込みいたします
                        <br />
                        <br />
                        参加費などの経費は差し引かせていただきます
                    </div>
                </section>
            </main>
        </div>
    );
}
