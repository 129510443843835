// utils
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { dateFormater, getParam } from "../assets/funcs/commonFuncs";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

// components
import Loading from "../components/uncommon/Loading";
import Section_title from "../components/common/Section_title";
import { Link, useNavigate } from "react-router-dom";
import { ChevronRight } from "akar-icons";
import { fetchURL } from "../assets/customHooks/fetchURL";

function News() {
    useScrollTop();
    const navigate = useNavigate();

    const [news, setNews] = useState(null);

    useEffect(() => {
        const id = getParam("id");
        const fetchData = async () => {
            let response;
            try {
                response = await fetch(`${fetchURL}/fetchContent.php?endpoint=news&id=${id}`);
                const body = await response.json();
                setNews(body);
            } catch (error) {
                console.warn(error);
                navigate("/NotFound");
            }
        };
        fetchData();
    }, []);

    return (
        <div className="page" id="News">
            <Section_title eng="News" jp="お知らせ" colorClass={"green"} />
            <main>
                <div className="inner">
                    {news ? (
                        <>
                            <div className="titleBar">
                                <p className="date">
                                    <span className="category">{news.category}</span>
                                    {dateFormater(news.publishedAt)}
                                </p>
                                <p className="title">{news.title}</p>
                            </div>
                            <div className="content">
                                {news?.thmbnail?.url && <img className="thumbnail" src={news.thmbnail.url} alt="" />}
                                <div className="texts">{parse(news.content)}</div>
                            </div>
                        </>
                    ) : (
                        <Loading height="400px" />
                    )}
                </div>
                <Link className="linkToList my-5 link_text" to="/News_list">
                    お知らせ一覧に戻る
                    <ChevronRight strokeWidth={2} size={15} />
                </Link>
            </main>
        </div>
    );
}

export default News;
