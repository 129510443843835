import React, { useEffect, useState, useRef } from "react";

// utils
import { useLocation, useNavigate } from "react-router-dom";
import tippy from "tippy.js";
import { useScrollTop } from "../assets/customHooks/useScrollTop";
import { getParam } from "../assets/funcs/commonFuncs";
import { dateFormater } from "../assets/funcs/commonFuncs.jsx";

// components
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Section_title from "../components/common/Section_title";
import { ChevronRight } from "akar-icons";
import Bby from "../components/common/Bby";
import MarketImage from "../components/common/MarketImage";
import { banner_zero, banner_hb, HbBannerText, ZeroBannerText } from "../assets/images/images";
import { useBby } from "../assets/customHooks/useBby";
import { useScreenWidth } from "../assets/customHooks/useScreenWidth";

// オークションごとに表示する開催日程の最大数
const VIEW_SCHEDULE_LENGTH = 6;

export default function Reservation({ allSchedules }) {
    useScrollTop();
    useBby();
    const location = useLocation();
    const navigate = useNavigate();

    const { isPcView, screenWidth } = useScreenWidth();

    const [activeTab, setActiveTab] = useState(0);
    const [tippyShowed, setTippyShowed] = useState(false);

    const { zeroMarketSchedules, hbMarketSchedules } = allSchedules;

    const swiperRef = useRef(null);
    const tabRef0 = useRef(null);
    const tabRef1 = useRef(null);

    const Conv = {
        ブランドオークション: "zero",
        HBオークション: "hb",
    };

    const tippyOption = { content: "タップで切り替え", offset: [0, 15], zIndex: 10 };

    useEffect(() => {
        if (!isPcView) {
            const param = getParam("auctionName");
            if (param === "hb") {
                setActiveTab(1);
                swiperRef.current.slideTo(1);

                if (!tippyShowed) {
                    const instance = tippy(tabRef0.current, tippyOption);
                    instance.show();
                    setTimeout(() => {
                        instance.hide();
                        setTimeout(() => {
                            instance.destroy();
                        }, 150);
                    }, 3000);
                    setTippyShowed(true);
                }
            } else {
                setActiveTab(0);
                swiperRef.current.slideTo(0);
                if (!tippyShowed) {
                    const instance = tippy(tabRef1.current, tippyOption);
                    instance.show();
                    setTimeout(() => {
                        instance.hide();
                        setTimeout(() => {
                            instance.destroy();
                        }, 150);
                    }, 3000);
                    setTippyShowed(true);
                }
            }
        }
    }, [location.search]);

    return (
        <div className="page" id="Reservation">
            <main>
                <Section_title eng="Reservation" jp={"参加申込"} colorClass={"green"} />
                <p className="desctipt wrapper">
                    ご参加には
                    <Bby>市場会員登録</Bby>
                    が必須となります。
                </p>
                {isPcView ? (
                    <>
                        <div className="flexContainer swiper">
                            <div className="swiper-slide">
                                <MarketImage bgImage={banner_zero} textImage={ZeroBannerText} />
                                {zeroMarketSchedules.length !== 0 ? (
                                    <>
                                        <ul className="schedules">
                                            {zeroMarketSchedules.map((schedule, index) => {
                                                if (index >= VIEW_SCHEDULE_LENGTH) return;
                                                const { date, auction_name, products } = schedule;
                                                const splitedDate = splitDate(date);
                                                return (
                                                    <li className="schedule_child" key={index}>
                                                        <Link
                                                            to={`/Form_entry?date=${dateFormater(date)}&auctionName=${
                                                                Conv[auction_name[0]]
                                                            }`}
                                                        >
                                                            <div className="splitedDate">
                                                                <span className="month">{splitedDate.thisMonth}</span>
                                                                <span className="slash"></span>
                                                                <span className="date">{splitedDate.thisDate}</span>
                                                                <span className="youbi">{splitedDate.thisYoubi}</span>
                                                            </div>
                                                            <span className="auction_name">
                                                                <p>
                                                                    {auction_name}　{products}
                                                                </p>
                                                            </span>
                                                            <div className="arrow">
                                                                <ChevronRight strokeWidth={3} size={14} />
                                                            </div>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : (
                                    <div className="noSchedule">現在開催予定はありません</div>
                                )}
                            </div>
                            <div className="swiper-slide">
                                <MarketImage bgImage={banner_hb} textImage={HbBannerText} isHb={true} />
                                {hbMarketSchedules.length !== 0 ? (
                                    <>
                                        <ul className="schedules">
                                            {hbMarketSchedules.map((schedule, index) => {
                                                if (index >= VIEW_SCHEDULE_LENGTH) return;
                                                const { date, auction_name, products } = schedule;
                                                const splitedDate = splitDate(date);
                                                return (
                                                    <li className="schedule_child" key={index}>
                                                        <Link
                                                            to={`/Form_entry?date=${dateFormater(date)}&auctionName=${
                                                                Conv[auction_name[0]]
                                                            }`}
                                                        >
                                                            <div className="splitedDate">
                                                                <span className="month">{splitedDate.thisMonth}</span>
                                                                <span className="slash"></span>
                                                                <span className="date">{splitedDate.thisDate}</span>
                                                                <span className="youbi">{splitedDate.thisYoubi}</span>
                                                            </div>
                                                            <span className="auction_name">
                                                                <p>
                                                                    {auction_name}　{products}
                                                                </p>
                                                            </span>
                                                            <div className="arrow">
                                                                <ChevronRight strokeWidth={3} size={14} />
                                                            </div>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : (
                                    <div className="noSchedule">現在開催予定はありません</div>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="tabs wrapper">
                            <div className="inner">
                                <div className={`indicator ${activeTab === 0 ? "left" : "right"}`}></div>
                                <div
                                    className={`tab ${activeTab === 0 ? "active" : ""}`}
                                    data-index="0"
                                    ref={tabRef0}
                                    onClick={() => handleTabClick(0)}
                                >
                                    ZERO
                                </div>
                                <div
                                    className={`tab ${activeTab === 1 ? "active" : ""}`}
                                    data-index="1"
                                    ref={tabRef1}
                                    onClick={() => handleTabClick(1)}
                                >
                                    HB
                                </div>
                            </div>
                        </div>
                        <Swiper
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            spaceBetween={15}
                            className="mySwiper"
                            allowTouchMove={false}
                            onSwiper={(swiper) => (swiperRef.current = swiper)}
                        >
                            <SwiperSlide>
                                <MarketImage bgImage={banner_zero} textImage={ZeroBannerText} />
                                {zeroMarketSchedules.length !== 0 ? (
                                    <>
                                        <ul className="schedules">
                                            {zeroMarketSchedules.map((schedule, index) => {
                                                if (index >= VIEW_SCHEDULE_LENGTH) return;
                                                const { date, auction_name, products } = schedule;
                                                const splitedDate = splitDate(date);
                                                return (
                                                    <li className="schedule_child" key={index}>
                                                        <Link
                                                            to={`/Form_entry?date=${dateFormater(date)}&auctionName=${
                                                                Conv[auction_name[0]]
                                                            }`}
                                                        >
                                                            <div className="splitedDate">
                                                                <span className="month">{splitedDate.thisMonth}</span>
                                                                <span className="slash"></span>
                                                                <span className="date">{splitedDate.thisDate}</span>
                                                                <span className="youbi">{splitedDate.thisYoubi}</span>
                                                            </div>
                                                            {screenWidth >= 767 ? (
                                                                <span className="auction_name">
                                                                    <p>
                                                                        {auction_name}　{products}
                                                                    </p>
                                                                </span>
                                                            ) : (
                                                                <span className="auction_name">
                                                                    <p>{auction_name}</p>
                                                                    <p>{products}</p>
                                                                </span>
                                                            )}

                                                            <div className="arrow">
                                                                <ChevronRight strokeWidth={3} size={14} />
                                                            </div>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : (
                                    <div className="noSchedule">現在開催予定はありません</div>
                                )}
                            </SwiperSlide>
                            <SwiperSlide>
                                <MarketImage bgImage={banner_hb} textImage={HbBannerText} isHb={true} />
                                {hbMarketSchedules.length !== 0 ? (
                                    <>
                                        <ul className="schedules">
                                            {hbMarketSchedules.map((schedule, index) => {
                                                if (index >= VIEW_SCHEDULE_LENGTH) return;
                                                const { date, auction_name, products } = schedule;
                                                const splitedDate = splitDate(date);
                                                return (
                                                    <li className="schedule_child" key={index}>
                                                        <Link
                                                            to={`/Form_entry?date=${dateFormater(date)}&auctionName=${
                                                                Conv[auction_name[0]]
                                                            }`}
                                                        >
                                                            <div className="splitedDate">
                                                                <span className="month">{splitedDate.thisMonth}</span>
                                                                <span className="slash"></span>
                                                                <span className="date">{splitedDate.thisDate}</span>
                                                                <span className="youbi">{splitedDate.thisYoubi}</span>
                                                            </div>
                                                            <span className="auction_name">
                                                                {auction_name}　{products}
                                                            </span>
                                                            <div className="arrow">
                                                                <ChevronRight strokeWidth={3} size={14} />
                                                            </div>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : (
                                    <div className="noSchedule">現在開催予定はありません</div>
                                )}
                            </SwiperSlide>
                        </Swiper>
                    </>
                )}
            </main>
        </div>
    );
    function handleTabClick(index) {
        setActiveTab(index);
        swiperRef.current.slideTo(index);
        let newAuctionName;
        switch (index) {
            case 0:
                newAuctionName = "zero";
                break;
            case 1:
                newAuctionName = "hb";
                break;
            default:
                break;
        }
        navigate(`?auctionName=${newAuctionName}`); // URLパラメータを更新
    }
    function splitDate(date) {
        const d = new Date(date);
        const thisMonth = String(d.getMonth() + 1).padStart(2, "0");
        const thisDate = String(d.getDate()).padStart(2, "0");
        const youbi = d.getDay();

        const youbiArray = ["日", "月", "火", "水", "木", "金", "土"];
        const thisYoubi = youbiArray[youbi];
        return { thisMonth, thisDate, thisYoubi };
    }
}
