import React from "react";

/**
 * フッターコンポーネント
 * 
 * @returns {JSX.Element} フッター要素を返します。
 */
function Footer() {
    return (
        <footer>
            <p className="copyLight">copyright© office zero inc. all rights reserved.</p>
        </footer>
    );
}

export default Footer;
