// utils
import React from 'react';

// components
import { Outlet } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import { ShuppinUploaderProvider } from "./components/contexts/ShuppinUploaderContext";

export const Layout = () => {
    return (
        <>
            <ShuppinUploaderProvider>
                <Header />
                <Outlet />
                <Footer />
            </ShuppinUploaderProvider>
        </>
    );
};
