import { useState, useEffect } from "react";
import { fetchURL } from "./fetchURL";

/**
 * カスタムフック: useFetchMarketOverviewDetails
 * 
 * マーケット概要の詳細データを取得するためのフックです。
 * 
 * @returns {Object} - マーケット概要の詳細データを含むオブジェクト
 */
const useFetchMarketOverviewDetails = () => {
    const [MarketOverViewDetailsData, setMarketOverViewDetailsData] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            let response;
            response = await fetch(`${fetchURL}/fetchContent.php?endpoint=market_overview_details`);

            const body = await response.json();
            setMarketOverViewDetailsData(body.contents[0]);
        };
        fetchData();
    }, []);

    return { MarketOverViewDetailsData };
};

export default useFetchMarketOverviewDetails;
