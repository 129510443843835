import React from "react";

/**
 * Bby border-bottom-yellow コンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {React.ReactNode} props.children - 子要素
 * @returns {JSX.Element} - "bby" クラスを持つ span 要素
 */
function Bby(props) {
    return <span className="bby">{props.children}</span>;
}

export default Bby;
