import React, { useEffect, useState, useRef } from "react";

// utils
import parse from "html-react-parser";
import tippy from "tippy.js";
import { getParam } from "../assets/funcs/commonFuncs";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

// components
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Section_title from "../components/common/Section_title";
import MarketImage from "../components/common/MarketImage";
import Zoom from "react-medium-image-zoom";

// images
import { banner_zero, banner_hb, HbBannerText, ZeroBannerText } from "../assets/images/images";
import Bby from "../components/common/Bby";
import { useBby } from "../assets/customHooks/useBby";
import { useScreenWidth } from "../assets/customHooks/useScreenWidth";
import Loading from "../components/uncommon/Loading";

// Zoomコンポーネントの表示マージン
const ZOOM_MARGIN_PC = 100;
const ZOOM_MARGIN_MOBILE = 10;

export default function Market_overview({
    MarketOverViewDetailsData,
    MarketOverViewTopZeroData,
    MarketOverViewHbData,
}) {
    useScrollTop();
    useBby([MarketOverViewDetailsData]);

    const location = useLocation();
    const navigate = useNavigate();

    const { isPcView } = useScreenWidth();

    const [activeTab, setActiveTab] = useState(0);
    const [tippyShowed, setTippyShowed] = useState(false);

    const swiperRef = useRef(null);
    const tabRef0 = useRef(null);
    const tabRef1 = useRef(null);

    const tippyOption = { content: "タップで切り替え", offset: [0, 15], zIndex: 10 };

    useEffect(() => {
        if (!isPcView) {
            const param = getParam("auctionName");
            if (param === "hb") {
                setActiveTab(1);
                swiperRef.current.slideTo(1);

                if (!tippyShowed) {
                    const instance = tippy(tabRef0.current, tippyOption);
                    instance.show();
                    setTimeout(() => {
                        instance.hide();
                        setTimeout(() => {
                            instance.destroy();
                        }, 150);
                    }, 3000);
                    setTippyShowed(true);
                }
            } else {
                setActiveTab(0);
                swiperRef.current.slideTo(0);
                if (!tippyShowed) {
                    const instance = tippy(tabRef1.current, tippyOption);
                    instance.show();
                    setTimeout(() => {
                        instance.hide();
                        setTimeout(() => {
                            instance.destroy();
                        }, 150);
                    }, 3000);
                    setTippyShowed(true);
                }
            }
        }
    }, [location.search]);

    return (
        <div className="page" id="Market_overview">
            <main>
                <Section_title eng="Market Overview" jp="市場概要" colorClass={"green"} />
                <p className="desctipt">
                    当社では <Bby>ZERO</Bby>と<Bby>HBA</Bby>の２つの市場を開催しております。
                    <br />
                    ご参加には会員登録をして頂きます。
                    <br />
                    また、どちらかに会員登録して頂ければ２つの市場をご利用いただけます。
                </p>
                {isPcView ? (
                    <>
                        <div className="flexContainer">
                            {/* zero */}
                            <div className="market_child">
                                <MarketImage
                                    bgImage={banner_zero}
                                    textImage={ZeroBannerText}
                                    page={"Market_overview"}
                                />
                                <>
                                    {MarketOverViewTopZeroData ? (
                                        <>
                                            <p className="subtitle mt-3 ps-4">取扱商品</p>
                                            <div className="product_names bby">
                                                {MarketOverViewTopZeroData.productNames}
                                            </div>
                                            <div className="swiperContainer">
                                                <Swiper
                                                    slidesPerView={"auto"}
                                                    centeredSlides={true}
                                                    spaceBetween={10}
                                                    loop={true}
                                                    className={`cSwiper zero`}
                                                >
                                                    {MarketOverViewTopZeroData.productImage.map((product, index) => {
                                                        return (
                                                            <SwiperSlide key={index}>
                                                                <Zoom
                                                                    zoomMargin={
                                                                        isPcView ? ZOOM_MARGIN_PC : ZOOM_MARGIN_MOBILE
                                                                    }
                                                                    overlayBgColorStart="rgba(255, 255, 50, 0)"
                                                                    overlayBgColorEnd="rgba(0, 0, 0, 0.89)"
                                                                >
                                                                    <img src={`${product.url}?fit=crop&w=600&h=600`} />
                                                                </Zoom>
                                                            </SwiperSlide>
                                                        );
                                                    })}
                                                </Swiper>
                                            </div>
                                        </>
                                    ) : (
                                        <Loading height="400px" />
                                    )}
                                </>

                                {/* endpoint=market_overview */}
                                {MarketOverViewDetailsData["zero"] ? (
                                    <div className="cms_details ps-4">{parse(MarketOverViewDetailsData["zero"])}</div>
                                ) : (
                                    ""
                                )}

                                <div className="toForm">
                                    <p>入会フォームが開きます</p>
                                    <Link className="link_btn green mb-3" to="/Membership_terms?auctionName=zero">
                                        入会
                                    </Link>
                                </div>
                            </div>
                            {/* hb */}
                            <div className="market_child">
                                <MarketImage bgImage={banner_hb} textImage={HbBannerText} isHb={true} />
                                <>
                                    <p className="subtitle mt-3 ps-4">取扱商品</p>
                                    {MarketOverViewHbData ? (
                                        <>
                                            {MarketOverViewHbData.map((week, weekIndex) => {
                                                const weekTitle = week.week[0];
                                                return (
                                                    <div className="weekBlock" key={weekIndex}>
                                                        <div
                                                            className={
                                                                weekIndex !== 0
                                                                    ? "product_names bby mt-4"
                                                                    : "product_names bby"
                                                            }
                                                        >
                                                            {weekTitle}
                                                        </div>
                                                        <div className="swiperContainer">
                                                            <Swiper
                                                                slidesPerView={"auto"}
                                                                centeredSlides={true}
                                                                spaceBetween={10}
                                                                loop={true}
                                                                className={`cSwiper zero`}
                                                            >
                                                                {week.productImage.map((product, index) => {
                                                                    return (
                                                                        <SwiperSlide key={index}>
                                                                            <Zoom
                                                                                zoomMargin={
                                                                                    isPcView
                                                                                        ? ZOOM_MARGIN_PC
                                                                                        : ZOOM_MARGIN_MOBILE
                                                                                }
                                                                                overlayBgColorStart="rgba(255, 255, 50, 0)"
                                                                                overlayBgColorEnd="rgba(0, 0, 0, 0.89)"
                                                                            >
                                                                                <img
                                                                                    src={`${product.url}?fit=crop&w=600&h=600`}
                                                                                />
                                                                            </Zoom>
                                                                        </SwiperSlide>
                                                                    );
                                                                })}
                                                            </Swiper>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <Loading height="400px" />
                                    )}
                                </>
                                {MarketOverViewDetailsData["hb"] ? (
                                    <div className="cms_details ps-4">{parse(MarketOverViewDetailsData["hb"])}</div>
                                ) : (
                                    ""
                                )}
                                <div className="toForm">
                                    <p>入会フォームが開きます</p>
                                    <Link className="link_btn green mb-3" to="/Membership_terms?auctionName=hb">
                                        入会
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="tabs">
                            <div className="inner">
                                <div className={`indicator ${activeTab === 0 ? "left" : "right"}`}></div>
                                <div
                                    className={`tab ${activeTab === 0 ? "active" : ""}`}
                                    data-index="0"
                                    ref={tabRef0}
                                    onClick={() => handleTabClick(0)}
                                >
                                    ZERO
                                </div>
                                <div
                                    className={`tab ${activeTab === 1 ? "active" : ""}`}
                                    data-index="1"
                                    ref={tabRef1}
                                    onClick={() => handleTabClick(1)}
                                >
                                    HB
                                </div>
                            </div>
                        </div>
                        <Swiper
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            spaceBetween={17}
                            className="pSwiper"
                            allowTouchMove={false}
                            onSwiper={(swiper) => (swiperRef.current = swiper)}
                        >
                            {/* zero */}
                            <SwiperSlide>
                                <MarketImage
                                    bgImage={banner_zero}
                                    textImage={ZeroBannerText}
                                    page={"Market_overview"}
                                />
                                {/* endpoint=products */}
                                <>
                                    {MarketOverViewTopZeroData ? (
                                        <>
                                            <p className="subtitle mt-3 ps-4">取扱商品</p>
                                            <div className="product_names bby">
                                                {MarketOverViewTopZeroData.productNames}
                                            </div>
                                            <div className="swiperContainer">
                                                <Swiper
                                                    slidesPerView={"auto"}
                                                    centeredSlides={true}
                                                    spaceBetween={10}
                                                    loop={true}
                                                    className={`cSwiper zero`}
                                                >
                                                    {MarketOverViewTopZeroData.productImage.map((product, index) => {
                                                        return (
                                                            <SwiperSlide key={index}>
                                                                <Zoom
                                                                    zoomMargin={
                                                                        isPcView ? ZOOM_MARGIN_PC : ZOOM_MARGIN_MOBILE
                                                                    }
                                                                    overlayBgColorStart="rgba(255, 255, 50, 0)"
                                                                    overlayBgColorEnd="rgba(0, 0, 0, 0.89)"
                                                                >
                                                                    <img src={`${product.url}?fit=crop&w=600&h=600`} />
                                                                </Zoom>
                                                            </SwiperSlide>
                                                        );
                                                    })}
                                                </Swiper>
                                            </div>
                                        </>
                                    ) : (
                                        <Loading height="400px" />
                                    )}
                                </>

                                {/* endpoint=market_overview */}
                                {MarketOverViewDetailsData["zero"] ? (
                                    <div className="cms_details ps-4">{parse(MarketOverViewDetailsData["zero"])}</div>
                                ) : (
                                    ""
                                )}

                                <div className="toForm">
                                    <p>入会フォームが開きます</p>
                                    <Link className="link_btn green mb-3" to="/Membership_terms?auctionName=zero">
                                        入会
                                    </Link>
                                </div>
                            </SwiperSlide>

                            {/* hb */}
                            <SwiperSlide>
                                <MarketImage bgImage={banner_hb} textImage={HbBannerText} isHb={true} />
                                <>
                                    <p className="subtitle mt-3 ps-4">取扱商品</p>
                                    {MarketOverViewHbData ? (
                                        <>
                                            {MarketOverViewHbData.map((week, weekIndex) => {
                                                const weekTitle = week.week[0];
                                                return (
                                                    <div className="weekBlock" key={weekIndex}>
                                                        <div
                                                            className={
                                                                weekIndex !== 0
                                                                    ? "product_names bby mt-4"
                                                                    : "product_names bby"
                                                            }
                                                        >
                                                            {weekTitle}
                                                        </div>
                                                        <div className="swiperContainer">
                                                            <Swiper
                                                                slidesPerView={"auto"}
                                                                centeredSlides={true}
                                                                spaceBetween={10}
                                                                loop={true}
                                                                className={`cSwiper zero`}
                                                            >
                                                                {week.productImage.map((product, index) => {
                                                                    return (
                                                                        <SwiperSlide key={index}>
                                                                            <Zoom
                                                                                zoomMargin={
                                                                                    isPcView
                                                                                        ? ZOOM_MARGIN_PC
                                                                                        : ZOOM_MARGIN_MOBILE
                                                                                }
                                                                                overlayBgColorStart="rgba(255, 255, 50, 0)"
                                                                                overlayBgColorEnd="rgba(0, 0, 0, 0.89)"
                                                                            >
                                                                                <img
                                                                                    src={`${product.url}?fit=crop&w=600&h=600`}
                                                                                />
                                                                            </Zoom>
                                                                        </SwiperSlide>
                                                                    );
                                                                })}
                                                            </Swiper>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <Loading height="400px" />
                                    )}
                                </>
                                {MarketOverViewDetailsData["hb"] ? (
                                    <div className="cms_details ps-4">{parse(MarketOverViewDetailsData["hb"])}</div>
                                ) : (
                                    ""
                                )}
                                <div className="toForm">
                                    <p>入会フォームが開きます</p>
                                    <Link className="link_btn green mb-3" to="/Membership_terms?auctionName=hb">
                                        入会
                                    </Link>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </>
                )}
            </main>
        </div>
    );
    function handleTabClick(index) {
        setActiveTab(index);
        swiperRef.current.slideTo(index);
        let newAuctionName;
        switch (index) {
            case 0:
                newAuctionName = "zero";
                break;
            case 1:
                newAuctionName = "hb";
                break;
            default:
                break;
        }
        navigate(`?auctionName=${newAuctionName}`); // URLパラメータを更新
    }
}
