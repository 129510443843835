import { useState, useEffect } from "react";
import { fetchURL } from "./fetchURL";

/**
 * カスタムフック: useFetchMarketOverViewTopZero
 * 
 * マーケット概要のトップゼロデータを取得するためのフックです。
 * 
 * @returns {Object} - マーケット概要のトップゼロデータを含むオブジェクト
 */
const useFetchMarketOverViewTopZero = () => {
    const [MarketOverViewTopZeroData, setMarketOverViewTopZeroData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let response;
            response = await fetch(`${fetchURL}/fetchContent.php?endpoint=market_overview_zero`);

            const body = await response.json();
            setMarketOverViewTopZeroData(body.contents[0]);
        };
        fetchData();
    }, []);

    return { MarketOverViewTopZeroData };
};

export default useFetchMarketOverViewTopZero;
