// utils
import React, { useState } from "react";
import parse from "html-react-parser";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

// components
import Section_title from "../components/common/Section_title";
import { Link } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { styled } from "@mui/material/styles";

// Accordionのカスタムスタイル
const CustomAccordion = styled(Accordion)(() => ({
    backgroundColor: "white",
    boxShadow: "none",
}));

// AccordionSummaryのカスタムスタイル
const CustomAccordionSummary = styled(AccordionSummary)(({ expanded }) => ({
    color: expanded ? "blue" : "black",
    borderBottom: expanded ? "1px solid black" : "none",
}));

export default function QnA() {
    useScrollTop();
    const [expanded, setExpanded] = useState([]);

    const handleAccordionChange = (panel) => () => {
        let newExpanded = [];
        if (expanded.includes(panel)) {
            newExpanded = expanded.filter((child) => {
                return child !== panel;
            });
            setExpanded(newExpanded);
        } else {
            newExpanded = [...expanded, panel];
            setExpanded(newExpanded);
        }
    };

    return (
        <div className="page" id="QnA">
            <main>
                <Section_title eng="Q&A" jp="よくある質問" colorClass="green" />
                <div className="QAs wrapper">
                    {getQADatas().map((QA, pIndex) => {
                        return (
                            <div className="QA_child" key={pIndex}>
                                <p className="subtitle">{QA.title}</p>
                                {QA.subQAs.map((subQA, cIndex) => {
                                    return (
                                        <React.Fragment key={cIndex}>
                                            <CustomAccordion
                                                expanded={expanded.includes(`panel${pIndex}-${cIndex}`)}
                                                onChange={handleAccordionChange(`panel${pIndex}-${cIndex}`)}
                                            >
                                                <CustomAccordionSummary
                                                    expandIcon={
                                                        expanded.includes(`panel${pIndex}-${cIndex}`) ? (
                                                            <RemoveCircleIcon />
                                                        ) : (
                                                            <AddCircleIcon />
                                                        )
                                                    }
                                                >
                                                    <Typography>{subQA.question}</Typography>
                                                </CustomAccordionSummary>
                                                <AccordionDetails>
                                                    <p className="answer" to="/Reservation?auctionName=hb">
                                                        {parse(subQA.answer)}
                                                    </p>
                                                </AccordionDetails>
                                            </CustomAccordion>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <p className="bottom-texts text wrapper mt-3">
                    ご質問やご要望がございましたら、お気軽にお問い合わせください
                    <br />
                    お問い合わせはLINEからも可能です!
                </p>
                <div className="links">
                    <Link to="/Form_contact" className="link_btn">
                        お問い合わせ
                    </Link>
                    <a
                        href="https://s.lmes.jp/landing-qr/2005799632-Rkmb4nem?uLand=ZlpeeT"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link_btn line"
                    >
                        LINEからお問い合わせ
                    </a>
                </div>
            </main>
        </div>
    );
}

function getQADatas() {
    const QADatas = [
        {
            title: "参加資格について",
            subQAs: [
                {
                    question: "参加資格の条件を教えてください。",
                    answer: "各都道府県発行の「古物商許可証」をお持ちの方であれば、取扱区分に関わらずご参加いただけます。また、日本語で円滑なコミュニケーションが取れることも条件となります。",
                },
                {
                    question: "古物商許可証の申請中ですが、参加は可能でしょうか？",
                    answer: "申し訳ございませんが、古物営業法に基づき、許可証の取得後でなければご参加いただけません。",
                },
            ],
        },
        {
            title: "参加方法について",
            subQAs: [
                {
                    question: "初めて参加する場合の手続きを教えてください。",
                    answer: "参加には古物商許可証のご提示と市場会員登録が必須となります。<a href='/Market_overview' className='color_link d-block'>入会はこちら</a>",
                },
                {
                    question: "見学は可能ですか？",
                    answer: "はい、見学可能です。実際の雰囲気をご覧いただいた上でのご入会をお勧めしています。",
                },
            ],
        },
        {
            title: "商品について",
            subQAs: [
                {
                    question: "商品の状態確認や真贋判断はどのように行われますか？",
                    answer: "商品は会場内のテーブルを巡回しますので、実際に手に取ってご確認いただけます。真贋判定は、当市場の取扱実績に基づく独自の厳格な基準で行っています。",
                },
                {
                    question: "出品商品の構成比率を教えてください。",
                    answer: "おおよその構成比率は、バッグが80％、時計が10％、ジュエリーが10％となっています。ただし、これは目安であり、当日の出品状況によって多少変動する可能性があります。",
                },
                {
                    question: "出品商品の種類や特徴を教えてください。",
                    answer: "当市場では、ネット販売向け商品から実店舗向け商品まで幅広く取り扱っています。多様なブランドを揃えているのが特徴で、参加者の皆様から高い満足度をいただいております。",
                },
            ],
        },
        {
            title: "取引について",
            subQAs: [
                {
                    question: "精算方法を教えてください。",
                    answer: "精算は当日、現金で行います。オークション終了後に「売買清算書」を発行いたしますので、内容をご確認の上、お支払いください。なお、この清算書は古物営業法および税法上必要な書類ですので、大切に保管をお願いいたします。",
                },
                {
                    question: "競りの方式について教えてください。",
                    answer: "当市場では競り上がり方式を採用しています。1万円以下は500円単位、1万円以上は1,000円単位で競り上がります。なお、当市場では符丁（ふちょう）を一切使用しません。",
                },
            ],
        },
        {
            title: "出品について",
            subQAs: [
                {
                    question: "少量からの出品は可能ですか？",
                    answer: "はい、1点からでも喜んでお受けいたします。ぜひご出品ください。",
                },
                {
                    question: "匿名での出品は可能ですか？",
                    answer: "はい、可能です。事前にお申し出いただければ、社名を伏せての出品に対応いたします。この匿名出品サービスは多くのお客様にご利用いただき、好評を博しております。",
                },
                {
                    question: "当日参加できない場合の出品方法はありますか？",
                    answer: "ご安心ください。お客様に代わって委託販売を承っております。",
                },
                {
                    question: "出品した商品は必ず売却する必要がありますか？",
                    answer: "いいえ、必ずしも売却する必要はありません。ご希望の価格に達しない場合は、売却を見送ることができます。これは委託販売の場合も同様です。",
                },
            ],
        },
        {
            title: "その他の質問",
            subQAs: [
                {
                    question: "商品の持ち帰りが心配です。",
                    answer: "ご心配いりません。会場から宅配便での発送が可能です。関東近辺の場合、140サイズで1,000円（税別、佐川急便利用）となります。その他の地域については、お問い合わせください。着払いにも対応しております。",
                },
                {
                    question: "途中参加や途中退席は可能ですか？",
                    answer: "はい、可能です。事前にご連絡いただければ、お客様のご都合に合わせて柔軟に対応させていただきます。",
                },
                {
                    question: "初めての参加で売買に不安があります。",
                    answer: "ご心配ありません。経験豊富な競り師やアドバイザーが丁寧にサポートいたします。毎回多くの新規参加者の方々が、無事に商品を落札されています。",
                },
                {
                    question: "商品を入れる箱などは必要ですか？",
                    answer: "事務局にてダンボールを無料でご用意しております。ただし、手持ちでお帰りの際は、お手数ですが紙袋などをご持参ください。",
                },
                {
                    question: "会場の様子を教えてください。",
                    answer: "当会場は200名を収容できる常設施設です。館内は全面禁煙で、周辺に喫煙所も設けておりません。",
                },
                {
                    question: "会場の雰囲気はいかがですか？",
                    answer: "当市場の競り師（社長）の個性的な進行により、和やかな雰囲気が生まれています。最低限のマナーを守っていただければ、楽しく取引していただけます。",
                },
                {
                    question: "その他の質問がある場合はどうすればよいですか？",
                    answer: "ご質問やご要望がございましたら、どうぞお気軽にお問い合わせください。誠意を持って丁寧にお答えいたします。",
                },
            ],
        },
    ];
    return QADatas;
}
