// components
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./Layout";

// page components
import Home from "./pages/Home";
import Access from "./pages/Access";
import Company_Profile from "./pages/Company_Profile";
import Consignment_Sales from "./pages/Consignment_Sales";
import News_list from "./pages/News_list";
import Reservation from "./pages/Reservation";
import QnA from "./pages/QnA";
import Sell from "./pages/Sell";
import News from "./pages/News";
import Membership_terms from "./pages/Membership_terms.jsx";
import NotFound from "./pages/NotFound.jsx";
import Form_join from "./pages/Form_join.jsx";
import Market_overview from "./pages/Market_Overview.jsx";
import Form_entry from "./pages/Form_entry.jsx";
import Form_contact from "./pages/Form_contact.jsx";

// フェッチ
import useFetchNews from "./assets/customHooks/useFetchNews.jsx";
import useFetchReservation from "./assets/customHooks/useFetchReservation.jsx";
import useFetchTerms from "./assets/customHooks/useFetchTerms.jsx";
import useFetchMarketOverViewTopZero from "./assets/customHooks/useFetchMarketOverViewTopZero.jsx";
import useFetchMarketOverViewTopHb from "./assets/customHooks/useFetchMarketOverViewTopHb.jsx";
import useFetchMarketOverViewHb from "./assets/customHooks/useFetchMarketOverviewHb.jsx";
import useFetchTopImages from "./assets/customHooks/useFetchTopImages.jsx";
import useFetchMarketOverviewDetails from "./assets/customHooks/useFetchMarketOverviewDetails.jsx";


function App() {
    const { topImages } = useFetchTopImages();
    const { allNews, splitedNews } = useFetchNews();
    const { allSchedules } = useFetchReservation();
    const { allTerms } = useFetchTerms();
    const { MarketOverViewTopZeroData } = useFetchMarketOverViewTopZero();
    const { MarketOverViewTopHbData } = useFetchMarketOverViewTopHb();
    const { MarketOverViewHbData } = useFetchMarketOverViewHb();
    const { MarketOverViewDetailsData } = useFetchMarketOverviewDetails();

    return (
        <BrowserRouter basename={"/"}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route
                        index
                        element={
                            <Home
                                topImages={topImages}
                                allNews={allNews}
                                allSchedules={allSchedules}
                                MarketOverViewTopZeroData={MarketOverViewTopZeroData}
                                MarketOverViewTopHbData={MarketOverViewTopHbData}
                            />
                        }
                    />

                    {/* 参加予約 */}
                    <Route path="Reservation" element={<Reservation allSchedules={allSchedules} />} />

                    {/* 会場案内、アクセス */}
                    <Route path="Access" element={<Access />} />

                    {/* よくある質問 */}
                    <Route path="QnA" element={<QnA />} />

                    {/* 出品 */}
                    <Route path="Sell" element={<Sell />} />

                    {/* お知らせ一覧 */}
                    <Route path="News_list" element={<News_list splitedNews={splitedNews} />} />

                    {/* お知らせ（ID */}
                    <Route path="News" element={<News />} />

                    {/* 委託販売 */}
                    <Route path="Consignment_Sales" element={<Consignment_Sales />} />

                    {/* 会員規約（会員登録フォームの前ページ） */}
                    <Route path="Membership_terms" element={<Membership_terms allTerms={allTerms} />} />

                    {/* 会社概要 */}
                    <Route path="Company_Profile" element={<Company_Profile />} />

                    {/* 市場概要 */}
                    <Route
                        path="Market_overview"
                        element={
                            <Market_overview
                                MarketOverViewDetailsData={MarketOverViewDetailsData}
                                MarketOverViewTopZeroData={MarketOverViewTopZeroData}
                                MarketOverViewHbData={MarketOverViewHbData}
                            />
                        }
                    />

                    {/* お問い合わせフォーム */}
                    <Route path="Form_contact" element={<Form_contact />} />

                    {/* 入会フォーム */}
                    <Route path="Form_join" element={<Form_join  allSchedules={allSchedules}/>} />

                    {/* 参加フォーム */}
                    <Route path="Form_entry" element={<Form_entry allSchedules={allSchedules}/>} />

                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
