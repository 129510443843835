// utils
import React from "react";

/**
 * マーケットイメージコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.bgImage - 背景画像のURL
 * @param {string} props.textImage - テキスト画像のURL
 * @param {boolean} props.isHb - 特定のスタイルを適用するかどうかを示すフラグ
 * @returns {JSX.Element} マーケットイメージ要素
 */
function MarketImage({ bgImage, textImage, isHb }) {
    return (
        <div className="marketImage">
            <img className="bgImage" src={bgImage} alt="" />
            <img src={textImage} className={isHb ? "textImage isHb" : "textImage"} alt="" />
        </div>
    );
}

export default MarketImage;