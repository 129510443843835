// utils
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import { notfound } from "../assets/images/images";

const RETURN_TOP_DELAY = 3000;

function NotFound() {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate("/");
        }, RETURN_TOP_DELAY);
    }, []);

    return (
        <div className="page" id="NotFound">
            <main>
                <img src={notfound} alt="" />
                <span className="center">3秒後にTOPに戻ります</span>
            </main>
        </div>
    );
}

export default NotFound;
