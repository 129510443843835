import { useState, useEffect } from "react";
import { fetchURL } from "./fetchURL";

// 1ページ当たりのページ数
const News_list_PAGENATION_SPLIT_LENGTH = 10;

/**
 * カスタムフック: useFetchNews
 * 
 * ニュースデータを取得し、ページネーション用に分割するためのフックです。
 * 
 * @returns {Object} - 全てのニュースデータと分割されたニュースデータを含むオブジェクト
 */
const useFetchNews = () => {
    const [allNews, setAllNews] = useState(null);
    const [splitedNews, setSplitedNews] = useState(null);

    useEffect(() => {
        // console.log(`fetch target:${fetchURL}`);
        
        const fetchData = async () => {
            let response;
            let body;
            try {
                const url = `${fetchURL}/fetchContent.php?endpoint=news`
                response = await fetch(url);
                body = await response.json();
                // console.log(body);
                
            } catch (error) {
                console.log(response);
                console.log(error);
            }

            let newsArray;
            newsArray = body.contents;
            newsArray.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
            setAllNews(newsArray);

            const splitNewsArray = [];
            for (let i = 0; i < newsArray.length; i += News_list_PAGENATION_SPLIT_LENGTH) {
                splitNewsArray.push(newsArray.slice(i, i + News_list_PAGENATION_SPLIT_LENGTH));
            }
            setSplitedNews(splitNewsArray);
        };
        fetchData();
    }, [News_list_PAGENATION_SPLIT_LENGTH]);

    return { allNews, splitedNews };
};

export default useFetchNews;
