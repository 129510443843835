import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

/**
 * Bootstrap Toast コンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.show - トーストを表示するかどうか
 * @param {function} props.onClose - トーストを閉じるためのコールバック関数
 * @param {Array<string>} props.messages - トーストに表示するメッセージの配列
 * @param {number} [props.delay=3000] - トーストが自動的に閉じるまでの遅延時間（ミリ秒）
 * @param {string} [props.position="top-center"] - トーストの表示位置
 * @returns {JSX.Element} トーストコンポーネント
 */
function BsToast({ show, onClose, messages, delay = 3000, position = "top-center" }) {
    return (
        <ToastContainer position={position} className="p-3 fixed-top custom-toast-container">
            <Toast show={show} onClose={onClose} delay={delay} autohide style={{ backgroundColor: "white" }}>
                <Toast.Header>
                    <strong className="me-auto">通知</strong>
                </Toast.Header>
                <Toast.Body>
                    {Array.isArray(messages) && messages.length > 0
                        ? messages.map((message, index) => {
                              return <p key={index}>・{message}</p>;
                          })
                        : ""}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default BsToast;
