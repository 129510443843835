import { useState, useEffect } from "react";

/**
 * カスタムフック: useScreenWidth
 * 
 * 現在の画面幅とPCビューかどうかを判定するためのフックです。
 * 
 * @returns {Object} - 画面幅とPCビュー判定を含むオブジェクト
 * @property {number} screenWidth - 現在の画面幅
 * @property {boolean} isPcView - PCビューかどうかの判定
 */
export function useScreenWidth() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isPcView, setIsPcView] = useState(window.innerWidth >= 1200);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setIsPcView(screenWidth >= 1200);
    }, [screenWidth]);

    // useEffect(() => {
    //     if (isPcView) {
    //         console.log("current view : PC");
    //     } else {
    //         console.log("current view : mobile");
    //     }
    //     if (isPcView === undefined) {
    //         console.log("?????");
    //     }
    // }, [isPcView]);

    return { screenWidth, isPcView };
}
