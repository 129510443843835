// utils
import React from "react";
import parse from "html-react-parser";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

//components
import Section_title from "../components/common/Section_title";

// images
import { shacho } from "../assets/images/images";

export default function Company_Profile() {
    useScrollTop();
    return (
        <div className="page" id="Company_Profile">
            <main>
                <Section_title eng="Company overview" jp="会社概要" colorClass="green" />
                <section className="details wrapper mb-4">
                    {Company_Profile_datasets().map((dataset, index) => {
                        return (
                            <React.Fragment key={index}>
                                <p className="subtitle">{dataset[0]}</p>
                                <div className="detail">{parse(dataset[1])}</div>
                            </React.Fragment>
                        );
                    })}
                </section>
                <Section_title eng="Company Philosophy" jp="会社理念" colorClass="orange" />
                <section className="PHILOSOPHY wrapper">
                    <p className="subtitle mt-3">信念</p>
                    <div className="detail">
                        人と人の結び付きを大切に生きるオフィスZEROは「二次流通プロデュースカンパニー」として、リサイクル業界の発展に少しでも貢献出来る事業展開をしております。
                        <br />
                        <br />
                        日々変わる経済状況の中で、お客様のニーズも変わっている現在、安定した仕入れ・商品構成の見直し・在庫処分の敏速化が勝ち残る手段であると考えております。
                        <br />
                        <br />
                        当社は、商品アイテム別に市場を開催しており登録のお客様は全て参加頂けます
                    </div>
                    <p className="subtitle mt-3">市場ポリシー</p>
                    <div className="detail">
                        笑い溢れる楽しい市場
                        <br />
                        「人が集まる」「物が集まる」
                        <br />
                        <br />
                        <p className="center">
                            代表取締役 山<span className="font_saki">﨑</span> 和伸
                        </p>
                        <img className="center" src={shacho} alt="shacho" />
                    </div>
                </section>
            </main>
        </div>
    );
}

function Company_Profile_datasets() {
    const datasets = [
        [`社名`, `株式会社オフィスZERO(本社 横浜市)`],
        [`代表者`, `山<span className="font_saki">﨑</span> 和伸`],
        [
            `事務局所在地`,
            `〒222-0033<br />
            横浜市港北区新横浜2-7-19 竹生第二ビル3Ｆ`,
        ],
        [`TEL`, `045-620-5513`],
        [`E-mail`, `moushikomi@auction-zero.com(お問い合わせ対応アドレス)`],
        [`設立`, `2011年`],
        [
            `役員等`,
            `専務取締役 竹林 しのぶ<br />
            スタッフ 10人(アルバイト含む)`,
        ],
        [`古物許可証番号`, `神奈川県公安委員会 第451470002965号`],
        [`古物市場主許可番号`, `神奈川県公安委員会 第452780006399号`],
        [
            `事業内容`,
            `ブランドオークションZEROの開催(新横浜)<br /> 
            HBアパレルオークションの運営(新横浜) <br />
            HB道具オークションの運営(新横浜) <br />
            業者卸トレーディングZEROの運営(横浜)`,
        ],
        [`顧問弁護士`, `河田法律事務所(京都弁護士会所属)`],
        [
            `取引銀行`,
            `三菱東京UFJ銀行 二俣川支店 <br />
            りそな銀行 三ツ境支店`,
        ],
    ];
    return datasets;
}
