// utils
import React, { useEffect, useState } from "react";
import { disableBodyScroll, getParam } from "../../assets/funcs/commonFuncs";

// components
import { Link, useLocation } from "react-router-dom";
import { ThreeLineHorizontal } from "akar-icons";
import HamburgerMenu from "./HamburgerMenu";

// images
import { logo_zero } from "../../assets/images/images.jsx";
import { HBA_Logo } from "../../assets/images/images";

const HAMBURGER_BTN_SIZE = 35;

/**
 * ヘッダーコンポーネント
 *
 * @returns {JSX.Element} ヘッダー要素を返します。
 */
export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [auctionName, setAuctionName] = useState("");
    const location = useLocation();

    const toggleMenu = (bool) => {
        if (bool) setIsOpen(bool);
        setIsOpen(!isOpen);
        disableBodyScroll(bool);
    };

    const logoClickHandler = () => {
        if (location.pathname === "/") {
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            localStorage.removeItem("isLoaded");
            setIsOpen(false);
        }
    };

    // パラメーターにあるオークション名によってロゴを変更
    useEffect(() => {
        const auctionName = getParam("auctionName");
        setAuctionName(auctionName);
    }, [location.search]);

    return (
        <>
            <header>
                <div className={auctionName === "hb" ? "pb-2 logo" : "logo"}>
                    <Link to="/" onClick={logoClickHandler}>
                        {/* パラメーターにあるオークション名によってロゴを変更 */}
                        <img src={auctionName === "hb" ? HBA_Logo : logo_zero} alt="logo_zero" />
                    </Link>
                </div>
                <div className="right">
                    <div id="hamburgerBtn" onClick={toggleMenu}>
                        <ThreeLineHorizontal strokeWidth={2} size={HAMBURGER_BTN_SIZE} />
                        <p>メニュー</p>
                    </div>
                </div>
                <HamburgerMenu isOpen={isOpen} toggleMenu={toggleMenu} HAMBURGER_BTN_SIZE={HAMBURGER_BTN_SIZE} />
            </header>
        </>
    );
}
