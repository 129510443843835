// utils
import React from "react";
import { dlHandler } from "../assets/funcs/commonFuncs";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

// components
import Section_title from "../components/common/Section_title";
import { Link } from "react-router-dom";
import { CloudUpload, Download, LinkOut } from "akar-icons";
import { useContext } from "react";
import { ShuppinUploaderContext } from "../components/contexts/ShuppinUploaderContext";

export default function Sell() {
    useScrollTop();

    // Contextから出品アップローダーの表示ステートを変更するset関数を取得
    const { setShuppinUploaderVisible } = useContext(ShuppinUploaderContext);

    const toggleShuppinUploaderVisible = (visible) => {
        setShuppinUploaderVisible(visible);
    };

    return (
        <div className="page" id="Sell">
            <main>
                <Section_title eng="Sell" jp="出品" colorClass="green" />
                <section className="wrapper">
                    <p className="subtitle">特徴</p>
                    <div className="texts">
                        ･ネット業者様・店舗販売業者様・海外輸出業者様等、幅広いビジネススタンスの方々が参加されておりますので、幅広い商品構成で出品することが可能です。{" "}
                        <br />
                        <br />
                        ･参加者は全国から集まるため、地域ごとの売れ筋商品の違いを活かせます。自社の滞留在庫を出品することで、新たなビジネス展開のきっかけが生まれます。
                        <br />
                        <br />
                        ･委託販売について
                        <br />
                        当日参加できない場合でもお客様に代わって委託販売を承っております。
                        <Link to="/Consignment_Sales" className="link_btn center my-3">
                            委託販売
                            <LinkOut size={20} />
                        </Link>
                        ･匿名出品について
                        <br />
                        社名を伏せての出品に対応しておりますので、事前にお申し出ください。
                    </div>
                    <p className="subtitle mt-5">出品の手順</p>
                    <ol className="steps">
                        <li>
                            <p className="title">出品リストの作成</p>
                            <div className="texts">指定の出品リストをダウンロードし記入</div>
                        </li>
                        <p
                            className="link_btn center my-3"
                            onClick={() => {
                                dlHandler("shuppinhyou");
                            }}
                        >
                            ダウンロード
                            <Download strokeWidth={2} size={20} />
                        </p>
                        <li>
                            <p className="title">番号札付け</p>
                            <div className="texts">
                                作成した出品リストに基づき、各商品に番号札を取り付ける <br />
                                ※複数点のまとめなどは外れないようにまとめてください
                                <br />
                                <br />
                                <span className="bold">まとめ売り・箱売りの商品</span>
                                <br />
                                希望価格がない場合はリストの作成は不要ですが、事前に出品の有無をご連絡ください
                            </div>
                        </li>
                        <li>
                            <p className="title">商品の搬入</p>
                            <div className="texts">
                                開催日前日の午前中までに商品と出品リストを弊社に届くようにお願いいたします
                            </div>
                        </li>
                        <p
                            className="link_btn center my-3"
                            onClick={() => {
                                toggleShuppinUploaderVisible(true);
                            }}
                        >
                            アップロード
                            <CloudUpload strokeWidth={2} size={20} />
                        </p>
                    </ol>
                </section>
            </main>
        </div>
    );
}
