// utils
import React from "react";
import { dateFormater } from "../assets/funcs/commonFuncs";
import { Pagination } from "swiper/modules";
import { useScrollTop } from "../assets/customHooks/useScrollTop";

// components
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Section_title from "../components/common/Section_title";
import { CircleChevronRightFill } from "akar-icons";
import Loading from "../components/uncommon/Loading";

function News_list({ splitedNews }) {
    useScrollTop();
    return (
        <div className="page" id="News_list">
            <main>
                <Section_title eng="News" jp="お知らせ" colorClass={"green"} />
                <div className="swiperContainer">
                    <Swiper
                        slidesPerView={"auto"}
                        centeredSlides={true}
                        spaceBetween={5}
                        pagination={{
                            el: ".swiper-pagination",
                            type: "bullets",
                            clickable: true,
                            renderBullet: (index, className) => {
                                return '<span class="' + className + '">' + (index + 1) + "</span>";
                            },
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {splitedNews ? (
                            splitedNews.map((splitedNews, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <ul className="newsList">
                                            {splitedNews.map((news_child, index) => {
                                                const { publishedAt, title, id } = news_child;
                                                return (
                                                    <li className="news_child" key={index}>
                                                        <Link to={`/News?id=${id}`}>
                                                            <span className="date">
                                                                {dateFormater(publishedAt, "mmdd")}
                                                            </span>
                                                            <span className="news_title">{title}</span>
                                                            <div className="arrow">
                                                                <CircleChevronRightFill strokeWidth={2} size={14} />
                                                            </div>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </SwiperSlide>
                                );
                            })
                        ) : (
                            <Loading height="400px" />
                        )}
                    </Swiper>
                    <div className="swiper-pagination"></div>
                </div>
            </main>
        </div>
    );
}

export default News_list;
