import React from "react";

// components
import { ClipLoader } from "react-spinners";

/**
 * Loading コンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.height - ローディングコンポーネントの高さ
 * 
 * @returns {JSX.Element} ローディングスピナーを表示する JSX 要素
 */
const Loading = (props) => {
    const { height } = props;
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: height }}>
            <ClipLoader color="#36D7B7" size={50} />
        </div>
    );
};

export default Loading;
