// utils
import React, { useRef } from "react";
import { useInterObserver } from "../../assets/customHooks/useInterObserver";
import parse from "html-react-parser";

// components
import Section_title from "../common/Section_title";
import { Link } from "react-router-dom";
import { ArrowDown } from "akar-icons";

// images
import { step1, step2, step3 } from "../../assets/images/images";

/**
 * Flow_sec コンポーネント
 * 
 * @returns {JSX.Element} ご利用開始までの流れを表示するセクションのJSX要素
 */
export default function Flow_sec() {
    const innerRef = useRef(null);
    useInterObserver([innerRef], 0.1);

    const contentRef = useRef(null);
    useInterObserver([contentRef], 0.2, 200);

    return (
        <section id="Flow_sec">
            <div className="inner" ref={innerRef}>
                <Section_title eng="Flow" jp="ご利用開始までの流れ" colorClass="orange" />
                <div className="interSectContainer" ref={contentRef}>
                    <ul className="steps">
                        {getSteps().map((step, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <li className="step">
                                        <div className="title">
                                            <span className="left">
                                                <span className="num">{index + 1}</span>
                                                {step.title}
                                            </span>
                                            <img className="icon" src={step.icon} alt={`${step.title} icon`} />
                                        </div>
                                        <div className="texts">{parse(step.texts)}</div>
                                    </li>
                                    {getSteps().length > index + 1 ? (
                                        <div className="arrowContainer">
                                            <ArrowDown strokeWidth={2} size={24} />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                    <Link className="link_btn green center mt-4" to="/Membership_terms?auctionName=zero">
                        入会はこちら
                    </Link>
                </div>
            </div>
        </section>
    );
}

/**
 * ステップ情報を取得する関数
 * 
 * @returns {Array<Object>} 各ステップの情報を含むオブジェクトの配列
 */
function getSteps() {
    return [
        {
            title: "お申込み",
            icon: step1,
            texts: "フォームにて必要事項を入力<br>※お申込みには古物商許可証が必要となります",
        },
        {
            title: "審査",
            icon: step2,
            texts: "審査には、1～3日ほどお時間を頂きます",
        },
        {
            title: "会員登録完了",
            icon: step3,
            texts: "メール・電話等にて<br>登録完了をお知らせ致します",
        },
    ];
}
