// utils
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

// images
import { logo_zero } from "../../assets/images/images.jsx";

/**
 * OpeningAnimation コンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {number} props.second - アニメーションの遅延時間（秒）
 * 
 * @returns {JSX.Element} 開始時のアニメーションを表示する JSX 要素
 */
const OpeningAnimation = ({ second }) => {
    const [isVisible, setIsVisible] = useState(true);

    const [openingAnimationPlayed, setOpeningAnimationPlayed] = useState(false);

    useEffect(() => {
        const item = sessionStorage.getItem("OpeningAnimation");
        setOpeningAnimationPlayed(item);
        if (!item) {
            sessionStorage.setItem("OpeningAnimation", true);
        }
    }, []);

    return (
        isVisible &&
        !openingAnimationPlayed && (
            <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: [1, 1, 0] }}
                transition={{ duration: 3.2, delay: second, times: [0, 0.8, 1] }}
                onAnimationComplete={() => setIsVisible(false)}
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 150,
                }}
            >
                {/* ロゴ */}
                <motion.div
                    animate={{ opacity: [0, 1, 1, 0], y: [-50, -20, -20, 10] }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.8, 1],
                    }}
                    className="logo"
                >
                    <img src={logo_zero} alt="Logo" />
                </motion.div>
            </motion.div>
        )
    );
};

export default OpeningAnimation;
