// utils
import React from "react";
import { useScrollTop } from "../assets/customHooks/useScrollTop";
// components
import Hero_sec from "../components/Home_sections/Hero_sec.jsx";
import Recommended_sec from "../components/Home_sections/Recommended_sec";
import Market_schedule_sec from "../components/Home_sections/Market_schedule_sec.jsx";
import News_sec from "../components/Home_sections/News_sec.jsx";
import Market_sec from "../components/Home_sections/Market_sec.jsx";
import OpeningAnimation from "../components/uncommon/OpeningAnimation";
import Flow_sec from "../components/Home_sections/Flow_sec";
import News_banner_sec from "../components/Home_sections/News_banner_sec";

export default function Home({ topImages, allNews, allSchedules, MarketOverViewTopZeroData, MarketOverViewTopHbData }) {
    useScrollTop();
    return (
        <div className="page" id="Home">
            <main>
                <Hero_sec topImages={topImages} />
                <News_banner_sec allNews={allNews}/>
                <News_sec allNews={allNews} />
                <Recommended_sec />
                <Market_sec
                    MarketOverViewTopZeroData={MarketOverViewTopZeroData}
                    MarketOverViewTopHbData={MarketOverViewTopHbData}
                />
                <Flow_sec />
                <Market_schedule_sec allSchedules={allSchedules} />
                <News_banner_sec allNews={allNews} />
            </main>
            <OpeningAnimation />
        </div>
    );
}
