import { useState, useEffect } from "react";
import { fetchURL } from "./fetchURL";

/**
 * カスタムフック: useFetchTerms
 * 
 * ゼロとHBの利用規約データを取得するためのフックです。
 * 
 * @returns {Object} - 全ての利用規約データを含むオブジェクト
 * @property {string} allTerms.zeroTerms - ゼロの利用規約
 * @property {string} allTerms.hbTerms - HBの利用規約
 */
const useFetchTerms = () => {
    const [zeroTerms, setZeroTerms] = useState("");
    const [hbTerms, setHbTerms] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            let responseZero;
            let responseHb;

            responseZero = await fetch(`${fetchURL}/fetchContent.php?endpoint=terms_zero`);
            responseHb = await fetch(`${fetchURL}/fetchContent.php?endpoint=terms_hb`);

            const bodyResponseZero = await responseZero.json();
            const bodyResponseHb = await responseHb.json();

            setZeroTerms(bodyResponseZero.terms);
            setHbTerms(bodyResponseHb.terms);
        };
        fetchData();
    }, []);

    return { allTerms: { zeroTerms, hbTerms } };
};

export default useFetchTerms;
