// utils
import React, { useRef } from "react";
import { useInterObserver } from "../../assets/customHooks/useInterObserver";

// components
import Section_title from "../common/Section_title";

/**
 * Recommended_sec コンポーネント
 *
 * @returns {JSX.Element} ZEROの特徴を表示するセクションのJSX要素
 */
export default function Recommended_sec() {
    const targetRef = useRef(null);
    useInterObserver([targetRef], 0.2);

    const pointRef1 = useRef(null);
    const pointRef2 = useRef(null);
    const pointRef3 = useRef(null);
    const pointRef4 = useRef(null);
    useInterObserver([pointRef1, pointRef2, pointRef3, pointRef4], 0.2);

    return (
        <section id="Recommended_sec">
            <div className="inner interSectContainer" ref={targetRef}>
                <Section_title eng="Point" jp="ZEROの特徴" colorClass="orange" />
                <div className="">
                    <ul className="points mt-5">
                        {/* 01 */}
                        <li className="point interSectContainer" ref={pointRef1}>
                            <div className="num">01.</div>
                            <div className="content">
                                <div className="title">
                                    毎週2回開催・月8回開催
                                    <br />
                                    ５商材を扱っているのでチャンス拡大！
                                </div>
                                <div className="texts">
                                    水曜日と金曜日の定期開催により
                                    <br />
                                    仕入れのチャンスがぐっと広がります！
                                    <br />
                                    <br />
                                    素早い現金化をお求めの事業者様にも最適です！
                                </div>
                            </div>
                        </li>
                        {/* 02 */}
                        <li className="point interSectContainer" ref={pointRef2}>
                            <div className="num">02.</div>
                            <div className="content">
                                <div className="title">
                                    予算に合わせて選べる！
                                    <br />
                                    幅広い価格帯の商品
                                </div>
                                <div className="texts">
                                    低単価商品から高単価商品まで、幅広い価格帯の商品が出品されているのも魅力です。
                                    あなたの予算に合わせて、賢く仕入れを行いましょう。
                                </div>
                            </div>
                        </li>
                        {/* 03 */}
                        <li className="point interSectContainer" ref={pointRef3}>
                            <div className="num">03.</div>
                            <div className="content">
                                <div className="title">
                                    大手流通企業のバックアップで好循環！
                                    <br />
                                    大量の商品流通を実現！
                                </div>
                                <div className="texts">
                                    上場企業の支援により、物資の流通量が非常に多く、結果的に決済金額やバイヤー参加数が常に増え続けています！
                                    <br />
                                    <br />
                                    毎年増収増益の安定感は大きな魅力です。 成約率93%以上!
                                </div>
                            </div>
                        </li>
                        {/* 04 */}
                        <li className="point interSectContainer" ref={pointRef4}>
                            <div className="num">04.</div>
                            <div className="content">
                                <div className="title">充実したサポートで初心者も安心！</div>
                                <div className="texts">
                                弊社スタッフが初心者の方でもオークションの説明、商品を落札できるようサポートします！
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
