import { useState, useEffect } from "react";
import {  fetchURL } from "./fetchURL";

/**
 * カスタムフック: useFetchMarketOverViewTopHb
 * 
 * マーケット概要のトップHBデータを取得するためのフックです。
 * 
 * @returns {Object} - マーケット概要のトップHBデータを含むオブジェクト
 */
const useFetchMarketOverViewTopHb = () => {
    const [MarketOverViewTopHbData, setMarketOverViewTopHbData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            let response;
            response = await fetch(`${fetchURL}/fetchContent.php?endpoint=market_overview_hb_top`);

            const body = await response.json();
            setMarketOverViewTopHbData(body.contents[0]);
        };
        fetchData();
    }, []);

    return { MarketOverViewTopHbData };
};

export default useFetchMarketOverViewTopHb;
